<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <h2 class="text-lg font-medium mr-auto">DTA Purchase</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        <router-link :to="{ name: 'dta-purchase-view-create' }" class="btn btn-primary shadow-md ml-3">Add
          Purchase</router-link>

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="input-quantity" class="form-label">Purchase No</label>
          <input id="input-quantity" class="form-control" placeholder="Purchase No" autocomplete="off"
            v-model="formData.purchase_no" type="text">
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Select Material</label>
          <TomSelect v-model="formData.product_id" :options="{
          placeholder: 'Select Material'
        }" @change="initTabulator" class="w-full" name="uqc" id="pos-form-2">
            <option value="0">Select Material</option>
            <option v-for="item in pagedata.products" :key="item.id" :value="item.id">{{ item.product_name }}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Select Specification</label>
          <TomSelect v-model="formData.material_id" @change="initTabulator" :options="{
          placeholder: 'Specification'
        }" class="w-full" name="Specification" id="pos-form-2">
            <option value="0">Select Specification</option>
            <option v-for="item in pagedata.materials" :key="item.id" :value="item.id">{{ item.description }}</option>
          </TomSelect>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button @click="resetFilters" class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button @click="initTabulator" class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!--- BEGIN: Purchase Details Model --->
    <div id="purchase-details-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex items-center justify-between w-full">
              <h2 class="font-medium text-base mr-auto">Purchase No : {{ pagedata.purchase_item.dta_code }}</h2>
              <div>
                <a data-dismiss="modal" href="javascript:;">
                  <XIcon class="w-8 h-8 text-gray-500" />
                </a>
              </div>
            </div>
          </div>
          <div class="modal-body p-10">

            <table class="w-full border mt-3">

              <tr class="">
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Gst Invoice No</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Gst Invoice Date</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Company Name</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Import Station</th>
              </tr>
              <tr class="">
                <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.gst_invoice_no }}
                </th>
                <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.gst_invoice_date }}
                </th>
                <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.companey_name }}
                </th>
                <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.station_name }}
                </th>
              </tr>
              <tr class="">
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">E
                  Way Bill No</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">E
                  Way Bill Date</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  PO NO</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  PO Date</th>
              </tr>
              <tr class="">
                <th class="py-2 border-b lg:text-m-r" colspan="2">{{ pagedata.purchase_item.eway_bill_no ?
          pagedata.purchase_item.eway_bill_no : '-' }}</th>
                <th class="py-2 border-b lg:text-m-r" colspan="2">{{ pagedata.purchase_item.eway_bill_date ?
          pagedata.purchase_item.eway_bill_date : '-' }}</th>
                <th class="py-2 border-b lg:text-md border-r" colspan="2">{{ pagedata.purchase_item.po_no ?
          pagedata.purchase_item.po_no : '-' }}</th>
                <th class=" py-2 border-b lg:text-m-r" colspan="2">{{ pagedata.purchase_item.po_date ?
          pagedata.purchase_item.po_date : '-' }}</th>
              </tr>
              <tr class="">
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Insurance No</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Insurance Amount</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Freigth Type</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Freigth</th>
              </tr>
              <tr class="">
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{
          pagedata.purchase_item.insurance_no ? pagedata.purchase_item.insurance_no : '-' }}</th>
                <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{
          pagedata.purchase_item.insurance ? pagedata.purchase_item.insurance + ' INR' : '-' }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  <span v-if="pagedata.purchase_item.freigth_type == 2">Ex-W</span>
                  <span v-if="pagedata.purchase_item.freigth_type == 3">For</span>
                  <span v-if="pagedata.purchase_item.freigth_type == 4">Godown Delivery</span>
                  <span v-if="pagedata.purchase_item.freigth_type == 1">Door Delivery</span>
                </th>
                <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r">{{ pagedata.purchase_item.freigth ?
          pagedata.purchase_item.freigth + ' INR' : '-' }}</th>
              </tr>
              <tr class="">
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Warehouse Receipt Time</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Inward Date</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Inward Amount</th>
                <th class="bg-gray-200 dark:bg-dark-1 py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">
                  Accessable Value</th>
              </tr>
              <tr class="">
                <th class=" py-2 border-b lg:text-md border-r" colspan="2">{{
          pagedata.purchase_item.warehouse_receipt_time ? pagedata.purchase_item.warehouse_receipt_time : '-' }}
                </th>
                <th class=" py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">{{
          pagedata.purchase_item.inward_date }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="5">{{
          pagedata.purchase_item.inward_value }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="5">{{
          pagedata.purchase_item.accessable_value }}</th>
              </tr>
            </table>

            <h2 class="font-medium text-base mr-auto mt-5">Purchase Products</h2>
            <table class="w-full border mt-3">
              <thead>
                <tr class="bg-gray-200 dark:bg-dark-1 border">
                  <th>Product Name</th>
                  <th>Material Specification</th>
                  <th>Product Type</th>
                  <th>Quantity</th>
                  <th>Per Unit Rate</th>
                  <th>Total Amount</th>
                  <th>GST</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in pagedata.purchase_item.purchase_details" class="text-center border"
                  :key="index">
                  <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_name }}</td>
                  <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.description }}</td>
                  <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.product_type == 1 ? "Poly" : (item.product_type == 2 ? "MBB" : (item.product_type == 4 ? "TopCon" : "All")) }}</td>
                  <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }} {{ item.uqc_name
                    }}</td>
                  <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.value_per_unit }}</td>
                  <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value }}</td>
                  <td class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst_value ? item.gst_value :
          '-' }} {{ item.gst && item.gst_value ? ' ( ' + item.gst + '% ) ' : '' }}</td>
                </tr>
              </tbody>

            </table>

            <h2 class="font-medium text-base mr-auto mt-5">Purchase Duties</h2>
            <table class="w-full border mt-3" v-for="(item, index) in pagedata.purchase_item.purchase_details"
              :key="index">
              <tr class="bg-gray-200 dark:bg-dark-1">
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="7">{{ index + 1 }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Product Name</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Material Specification</th>
              </tr>
              <tr>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.product_name }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.description }}</th>
              </tr>
              <tr class="bg-gray-100 dark:bg-dark-1">
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Quantity</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">UQC</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Product Amount</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Insurance</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Freigth</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">CIF Amount</th>
              </tr>
              <tr>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.uqc_name }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value ?
          parseFloat(item.accessable_value).toFixed(2) : '-' }}</th>
                <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.conversation_value && item.value_per_unit) ? (item.value_per_unit * item.conversation_value) : '' }}</th> -->
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.insurance ?
          parseFloat(item.insurance).toFixed(2) : '-' }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.freigth ?
                  parseFloat(item.freigth).toFixed(2) : '-' }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.total_access_weight ?
                  parseFloat(item.total_access_weight).toFixed(2) : '-' }}</th>
              </tr>
              <tr class="bg-gray-100 dark:bg-dark-1">
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r"></th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">GST</th>
                <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Comp Cess</th> -->
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Duty Total</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Total Amount With Duty</th>
              </tr>
              <tr>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">%</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst }}</th>
                <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess }}</th> -->
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="2" colspan="2">{{
                  item.duty_amount }}</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="2">{{
                  item.total_value }}</th>
              </tr>
              <tr>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Amount</th>
                <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst_value }}</th>
                <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess_value }}</th> -->
              </tr>
            </table>

          </div>
        </div>
      </div>
    </div>
    <!--- END: Purchase Details Model --->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, toRefs, onMounted, inject } from 'vue'
import feather from 'feather-icons'
import axios from 'axios'
import { BASE_URL } from '@/constant/config.js'
import { CheckSquareIcon } from '@zhuowenli/vue-feather-icons'
import router from '@/router'
import Tabulator from 'tabulator-tables'
import cash from 'cash-dom'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      materials: [],
      products: [],
      companies: [],
      stations: [],
      items: [],
      purchase_item: [],
      loading: false,
    });
    const tableRef = ref()
    const tabulator = ref()
    const formData = reactive({
      product_id: '0',
      material_id: '0',
      companey_id: '0',
      uqc_id: '0',
      station_id: '0',
      duty_paid: '0',
      purchase_no: '',
    })
    const swal = inject("$swal");
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + "api/purchase/get_dta_purchase_details",
        ajaxConfig: {
          method: "GET", //set request type to Position
          headers: {
            "Content-type": 'application/json; charset=utf-8', //set specific content type
            "Authorization": "Bearer " + localStorage.getItem("token")
          },
        },
        ajaxParams: {
          "product_id": formData.product_id != '0' ? window.btoa(formData.product_id) : '',
          "material_id": formData.material_id != '0' ? window.btoa(formData.material_id) : '',
          "companey_id": formData.companey_id != '0' ? window.btoa(formData.companey_id) : '',
          "uqc_id": formData.uqc_id != '0' ? window.btoa(formData.uqc_id) : '',
          "station_id": formData.station_id != '0' ? window.btoa(formData.station_id) : '',
          "duty_paid": formData.duty_paid != '0' ? window.btoa(formData.duty_paid) : '',
          "purchase_no": window.btoa(formData.purchase_no)
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        headerSort: false,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'DTA',
            minWidth: 100,
            responsive: 0,
            field: 'dta_code',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,

          },
          {
            title: 'Company',
            minWidth: 150,
            responsive: 0,
            field: 'companey_name',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter: "textarea"

          },
          {
            title: 'Bill No <br> Date',
            minWidth: 100,
            responsive: 0,
            field: 'bill',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().gst_invoice_no + '<br>' + cell.getData().gst_invoice_date
            }

          },
          {
            title: 'Description <br> of Good',
            minWidth: 140,
            responsive: 0,
            field: 'product_name',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter: "textarea"

          },
          {
            title: 'Specification',
            minWidth: 130,
            responsive: 0,
            field: 'description',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter: "textarea"

          },
          {
            title: 'Type',
            minWidth: 80,
            responsive: 0,
            field: 'type',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().product_type == 1 ? "Poly" : (cell.getData().product_type == 2 ? "MBB" : (cell.getData().product_type == 4 ? "TopCon" : "All"))
            }

          },
          {
            title: 'Quantity <br> Total (INR)',
            minWidth: 100,
            responsive: 0,
            field: 'quantity',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().quantity + ' ' + cell.getData().uqc_name + '<br>' + (cell.getData().accessable_value ? $h.myFloat(cell.getData().accessable_value) : '')
            }
          },
          {
            title: 'Status',
            // minWidth: 200,
            field: 'active_status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${cell.getData().active_status == 1 ? 'text-theme-9' : 'text-theme-6'
                }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${cell.getData().active_status == 1 ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: 'View',
            field: 'view',
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort: false,
            print: false,
            download: false,
            formatter(cell) {
              if (cell.getData().active_status == 1) {

                const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-primary p-1">
                        <i data-feather="check-square" class="w-4 h-4 mr-1"></i> View
                      </button>

                    </div>`)
                cash(a).on('click', function () {
                  getProductDetails(window.btoa(cell.getData().id))
                })

                return a[0]
              }
            }
          },
          {
            title: 'Edit',
            field: 'edit',
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort: false,
            print: false,
            download: false,
            formatter(cell) {
              if (cell.getData().active_status == 1) {

                const a = cash(`<div class="flex lg:justify-center items-center">
                      <a class="flex items-center mr-3" href="javascript:;">
                        <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                      </a>

                    </div>`)
                cash(a).on('click', function () {
                  router.push({ path: '/purchase/dta/update/' + window.btoa(cell.getData().id) })
                })

                return a[0]
              }
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    // };
    function updateroductDetails(id) {
      router.push({ path: '/purchase/dta/update/' + window.btoa(id) })
    }
    function getProductDetails(id) {
      // console.log(id);
      const params = {
        "purchase_id": id
      };
      let promise = axios({
        url: "api/purchase/get_dta_purchase_single_item",
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.purchase_item = data.items;
          cash('#purchase-details-modal').modal('show')
        })
    }
    const get_product_details = () => {
      let promise_url = "api/master/dp_product";
      let promise = axios({
        url: promise_url,
        method: "POST",
        // data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.products = data.items
        })
        .catch(_error => {
          pagedata.products = []

        });
    }

    const get_material_details = () => {
      let promise_url = "api/master/dp_material";
      let promise = axios({
        url: promise_url,
        method: "POST",
        // data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.materials = data.items
        })
        .catch(_error => {
          pagedata.materials = []

        });
    }
    const resetFilters = () => {
      formData.product_id = '0'
      formData.material_id = '0'
      formData.companey_id = '0'
      formData.uqc_id = '0'
      formData.station_id = '0'
      formData.duty_paid = '0'
      formData.purchase_no = ''

      initTabulator()
    }
    onMounted(() => {
      initTabulator();
      get_product_details();
      get_material_details();
    });
    return {
      pagedata,
      formData,
      initTabulator,
      updateroductDetails,
      tableRef,
      resetFilters,
      tabulator
    };
  },
  components: { CheckSquareIcon }
})
</script>
