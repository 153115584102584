<template>
<div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div>
            <div class="p-2">
                <div class="pos__tabs nav nav-tabs justify-between" role="tablist">
                    <h2 class="text-lg font-medium mr-auto">Module Stock</h2>
                    <div>

                        <a id="module-stock-tab" data-toggle="tab" data-target="#module-stock" href="javascript:;" class="btn btn-sm py-2 mx-2" :class="{'btn-primary text-white' : page_data.stock_type == 1, 'btn-outline-primary hover:text-white' : page_data.stock_type == 2}" role="tab" aria-controls="module-stock" aria-selected="true" @click="initTabulator(1)">Module Stock</a>
                        <a id="pow-stock-tab" data-toggle="tab" data-target="#pow-stock" href="javascript:;" class="btn btn-sm py-2 mx-2" :class="{'btn-primary text-white' : page_data.stock_type == 2, 'btn-outline-primary hover:text-white' : page_data.stock_type == 1}" role="tab" aria-controls="pow-stock" aria-selected="false" @click="initTabulator(2)">OW Stock</a>
                        <a data-toggle="modal" data-target="#release-stock-modal" href="javascript:;" class="btn btn-primary btn-sm mx-2 py-2">
                            Release Stock
                        </a>
                      </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-x-2 mt-5">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="input-quantity" class="form-label">OW NO</label>
                <input id="input-quantity" class="form-control" :disabled="page_data.stock_type == 1" placeholder="OW NO" autocomplete="off" v-model="outward_filters.request_code" type="text">
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Select Watt</label>
                <TomSelect v-model="outward_filters.watt_id" :options="{
                  placeholder: 'Select Watt'
                }" class="w-full" name="Watt" id="pos-form-2">
                    <option value="0">Select Watt</option>
                    <option v-for="item in page_data.watt_details" :key="item.id" :value="item.id">{{ item.watt}}</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Module Type</label>
                <TomSelect v-model="outward_filters.pannel_type" :options="{
                  placeholder: 'Select Module Type'
                }" class="w-full" name="Module Type" id="pos-form-2">
                    <option value="0">Select Module Type</option>
                    <option value="1">Poly</option>
                    <option value="2">MBB</option>
                    <option value="4">TopCon</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Status</label>
                <TomSelect v-model="outward_filters.status" :options="{
                  placeholder: 'Select Status'
                }" class="w-full" name="Status" id="pos-form-2">
                    <option value="0">Select Status</option>
                    <option value="1">Available Stock</option>
                    <option value="2">Sold Stock</option>
                    <option value="3">Cancelled</option>
                </TomSelect>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1">
                <div class="mt-5">
                    <button @click="resetStockFilters" class="btn btn-secondary w-full mt-2">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1">
                <div class="mt-5">
                    <button @click="initTabulator(page_data.stock_type)" class="btn btn-primary w-full mt-2">
                        Generate
                    </button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <!--- BEGIN: Consumption Products Model --->
    <div id="release-stock-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Release Stocks</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body p-0">

                    <div class="sm:grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 gap-2 border-b p-5">
                        <div>
                            <label for="regular-form-1" class="form-label">Select Party</label>
                            <div id="party_id">

                                <TomSelect v-model="form_data.party_id" :options="{
                        placeholder: 'Select Party'
                      }" class="w-full" name="Party" id="pos-form-2">
                                    <option value="0">Select Party</option>
                                    <option v-for="item in page_data.party_details" :key="item.id" :value="item.id">{{ item.companey_name}}</option>
                                </TomSelect>
                            </div>
                        </div>
                        <div>
                            <label for="regular-form-1" class="form-label">Invoice No</label>
                            <div>
                                <input v-model="form_data.invoice_no" type="text" id="invoice_no" class="form-control" placeholder="Invoice No">
                            </div>
                        </div>
                        <div>
                            <label for="regular-form-1" class="form-label">Sell Date</label>
                            <div>

                                <input type="date" id="sell_date" class="form-control" placeholder="" v-model="form_data.sell_date" />
                            </div>
                        </div>
                        <div>
                            <label for="regular-form-1" class="form-label">Total Modules</label>
                            <div>
                                <input v-model="form_data.total_modules" type="number" id="total_modules" disabled class="form-control" placeholder="Total Modules">
                            </div>
                        </div>
                        <div>
                            <div>

                            </div>
                        </div>
                    </div>

                    <div class="intro-y col-span-12 p-5">
                        <div class="intro-y">
                            <h2 class="font-medium text-lg mr-auto">Estimate Consumption</h2>
                            <div class="my-6 shadow">

                                <table class="w-full border mt-3">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Watt</th>
                                            <th>Type</th>
                                            <th>OW Stock</th>
                                            <th>Current Quantity</th>
                                            <th>Release Quantity</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="border-b" v-for="(plan,index) in form_data.pow_details" :key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td :id="'watt_id_' + index" class="p-0 m-0">
                                                <TomSelect v-model="plan.watt_id" :options="{
                        placeholder: 'Select Watt'
                      }" class="w-full" name="Watt" id="pos-form-2" @change="get_pow_details(plan)">
                                                    <option value="0">Select Watt</option>
                                                    <option v-for="item in page_data.watt_details" :key="item.id" :value="item.id">{{ item.watt}}</option>
                                                </TomSelect>
                                            </td>
                                            <td :id="'product_type_' + index" class="p-0 m-0">
                                                <TomSelect v-model="plan.product_type" :options="{
                        placeholder: 'Select Module Type'
                      }" class="w-full" name="Module Type" id="pos-form-2" @change="get_pow_details(plan)">
                                                    <option value="0">Select Type</option>
                                                    <option value="1">Poly</option>
                                                    <option value="2">MBB</option>
                                                    <option value="4">TopCon</option>
                                                </TomSelect>
                                            </td>
                                            <td :id="'pow_id_' + index" class="p-0 m-0">
                                                <TomSelect v-model="plan.pow_id" :options="{
                        placeholder: 'Select OW'
                      }" class="w-full" name="OW" id="pos-form-2" @change="set_module_items(plan)">
                                                    <option value="0">Select OW</option>
                                                    <option v-for="sb_itm in plan.pow_items" :key="sb_itm.id" :value="sb_itm.id">{{ sb_itm.request_code}}</option>
                                                </TomSelect>
                                            </td>
                                            <td class="w-32">
                                                <input v-model="plan.cr_quantity" type="number" :id="'cr_quantity_' + index" class="form-control w-full" placeholder="Quantity" disabled>
                                            </td>
                                            <td class="w-32">
                                                <input v-model="plan.sold_qty" type="number" :id="'sold_qty_' + index" class="form-control w-full" placeholder="Release Quantity" @change="validate_quantity(plan)">
                                            </td>
                                            <td>
                                                <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="remove_request_field(plan)">
                                                    <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer p-5">
                    <div class="flex items-center justify-between">
                        <div>
                            <!-- <button type="button" @click="add_new_request_field" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">Add Product</button> -->
                        </div>
                        <div>

                            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-32 mr-1">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary w-32" :disabled="form_data.loading" @click="release_stock">
                                Save Details
                                <LoadingIcon v-if="form_data.loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--- END: Consumption Products Model --->
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    onMounted,
    inject,
    computed,
    watch
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import axios from 'axios'
import {
    BASE_URL
} from '@/constant/config.js'
import cash from 'cash-dom'
import moment from 'moment'
import {
    helper as $h
} from '@/utils/helper'
import router from '@/router'
import store from '@/store'

export default defineComponent({
    setup() {
        const swal = inject('$swal')
        const tableRef = ref()
        const tabulator = ref()
        const tableRefOW = ref()
        const tabulatorOW = ref()
        const userDetails = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            stock_type: 2,
            count_ids: 1,
            watt_details: [],
            party_details: [],
            purchase_item: [],
            request_loading: false,
            purchase_loading: false,
            request_details: {
                request_item: [],
                request_products: [],
                new_quantity: ''
            },
            product_details: {
                product_detail: [],
                purchase_details: [],
                total: [],
                selected_quantity: 0,
                required_quantity: 0,
                limit_touch: false,
            },
            duty_assigned_arr: [],
            message: '',
            ref_no: '',
            deliver_id: ''
        })

        const form_data = reactive({
            url: 'api/osc-rs',
            pow_url: 'api/osc-gpd',
            loading: false,
            party_id: '0',
            invoice_no: '',
            sell_date: '',
            total_modules: '',
            pow_details: [{
                id: 1,
                watt_id: '0',
                product_type: '0',
                cr_quantity: '',
                sold_qty: '',
                pow_id: '0',
                pow_items: [],
            }],
        })

        const outward_filters = reactive({
            party_id: "0",
            watt_id: "0",
            pannel_type: "0",
            status: "0",
            request_code: "",
            invoice_no: "",
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD'),
            page_size: '1'
        })
        const print_filters = reactive({
            party_id: "0",
            watt_id: "0",
            pannel_type: "0",
            status: "0",
            request_code: "",
            invoice_no: "",
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD')
        })

        const initTabulator = (type) => {

            if (type == 1) {

                page_data.stock_type = 1

                print_filters.watt_id = outward_filters.watt_id
                print_filters.pannel_type = outward_filters.pannel_type
                print_filters.status = outward_filters.status
                print_filters.request_code = outward_filters.request_code
                print_filters.s_date = outward_filters.s_date
                print_filters.e_date = outward_filters.e_date

                setTimeout(() => {

                    tabulator.value = new Tabulator(tableRef.value, {
                        ajaxURL: BASE_URL + 'api/osc-gcsd',
                        ajaxConfig: {
                            method: "GET", //set request type to Position
                            headers: {
                                "Content-type": 'application/json; charset=utf-8', //set specific content type
                                "Authorization": "Bearer " + localStorage.getItem("token")
                            },
                        },
                        ajaxParams: {
                            "party_id": print_filters.party_id == "0" ? "" : window.btoa(print_filters.party_id),
                            "watt_id": print_filters.watt_id == "0" ? "" : window.btoa(print_filters.watt_id),
                            "pannel_type": print_filters.pannel_type == "0" ? "" : window.btoa(print_filters.pannel_type),
                            "status": print_filters.status == "0" ? "" : window.btoa(print_filters.status),
                            "request_code": print_filters.request_code == "" ? "" : window.btoa(print_filters.request_code),
                            "s_date": "",
                            // "s_date": print_filters.s_date == "" ? "" : window.btoa(print_filters.s_date),
                            "e_date": "",
                            // "e_date": print_filters.e_date == "" ? "" : window.btoa(print_filters.e_date),
                        },
                        pagination: 'remote',
                        paginationSize: 20,
                        // renderHorizontal:"virtual",
                        paginationSizeSelector: [20, 30, 40, 60],
                        ajaxFiltering: true,
                        ajaxSorting: true,
                        headerSort: false,
                        printAsHtml: true,
                        printStyled: true,
                        layout: 'fitColumns',
                        responsiveLayout: 'collapse',
                        placeholder: 'No matching records found',
                        columns: [{
                                title: 'Watt',
                                minWidth: 150,
                                responsive: 0,
                                field: 'watt',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter: "textarea",
                            },
                            {
                                title: 'Type',
                                minWidth: 100,
                                responsive: 0,
                                field: 'product_type',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                  return cell.getData().product_type == 1 ? "Poly" : (cell.getData().product_type == 2 ? "MBB" : (cell.getData().product_type == 4 ? "TopCon" : "All"))
                                },
                            },
                            // {
                            //     title: 'Current Modules',
                            //     minWidth: 100,
                            //     responsive: 0,
                            //     field: 'quantity',
                            //     hozAlign: 'center',
                            //     vertAlign: 'middle',
                            //     print: false,
                            //     download: false,
                            //     formatter(cell) {
                            //         return cell.getData().quantity
                            //     },
                            // },
                            {
                                title: 'Current Modules',
                                minWidth: 100,
                                responsive: 0,
                                field: 'quantity',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    return cell.getData().cr_quantity ? cell.getData().cr_quantity : '-'
                                }
                            },
                            {
                                title: 'Status',
                                minWidth: 100,
                                field: 'active_status',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    if (cell.getData().active_status == 1) {

                                        return '<div class="flex items-center lg:justify-center text-theme-1">Available</div>'

                                    } else if (cell.getData().active_status == 2) {

                                        return '<div class="flex items-center lg:justify-center text-theme-3">Sold</div>'

                                    } else if (cell.getData().active_status == 3) {

                                        return '<div class="flex items-center lg:justify-center text-theme-6">Cancelled</div>'

                                    }
                                }
                            },

                        ],
                        renderComplete() {
                            feather.replace({
                                'stroke-width': 1.5
                            })
                        }
                    })

                    sessionStorage.setItem('stock_department_requset_filters', JSON.stringify(outward_filters))
                }, 50);

            } else if (type == 2) {
                page_data.stock_type = 2

                print_filters.watt_id = outward_filters.watt_id
                print_filters.pannel_type = outward_filters.pannel_type
                print_filters.status = outward_filters.status
                print_filters.request_code = outward_filters.request_code
                print_filters.s_date = outward_filters.s_date
                print_filters.e_date = outward_filters.e_date

                setTimeout(() => {
                    tabulator.value = new Tabulator(tableRef.value, {
                        ajaxURL: BASE_URL + 'api/osc-gsd',
                        ajaxConfig: {
                            method: "GET", //set request type to Position
                            headers: {
                                "Content-type": 'application/json; charset=utf-8', //set specific content type
                                "Authorization": "Bearer " + localStorage.getItem("token")
                            },
                        },
                        ajaxParams: {
                            "party_id": print_filters.party_id == "0" ? "" : window.btoa(print_filters.party_id),
                            "watt_id": print_filters.watt_id == "0" ? "" : window.btoa(print_filters.watt_id),
                            "pannel_type": print_filters.pannel_type == "0" ? "" : window.btoa(print_filters.pannel_type),
                            "status": print_filters.status == "0" ? "" : window.btoa(print_filters.status),
                            "request_code": print_filters.request_code == "" ? "" : window.btoa(print_filters.request_code),
                            "s_date": "",
                            "e_date": "",
                        },
                        pagination: 'remote',
                        paginationSize: 20,
                        // renderHorizontal:"virtual",
                        paginationSizeSelector: [20, 30, 40, 60],
                        ajaxFiltering: true,
                        ajaxSorting: true,
                        headerSort: false,
                        printAsHtml: true,
                        printStyled: true,
                        layout: 'fitColumns',
                        responsiveLayout: 'collapse',
                        placeholder: 'No matching records found',
                        columns: [

                            {
                                title: 'OW NO',
                                minWidth: 150,
                                responsive: 0,
                                field: 'request_code',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter: "textarea",
                            },
                            // For HTML table
                            {
                                title: 'Watt',
                                minWidth: 150,
                                responsive: 0,
                                field: 'watt',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter: "textarea",
                                // cellClick: function (e, cell) {
                                //     router.push('/stock/outward-request/' + window.btoa(cell.getData().id))
                                // }
                            },
                            {
                                title: 'Type',
                                minWidth: 100,
                                responsive: 0,
                                field: 'product_type',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                  return cell.getData().product_type == 1 ? "Poly" : (cell.getData().product_type == 2 ? "MBB" : (cell.getData().product_type == 4 ? "TopCon" : "All"))
                                },
                            },
                            {
                                title: 'Final Modules',
                                minWidth: 100,
                                responsive: 0,
                                field: 'quantity',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    return cell.getData().quantity ? cell.getData().quantity : '0'
                                },
                            },
                            {
                                title: 'Current Modules',
                                minWidth: 100,
                                responsive: 0,
                                field: 'cr_quantity',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    return cell.getData().cr_quantity ? cell.getData().cr_quantity : '0'
                                },
                            },
                            {
                                title: 'Sold Modules',
                                minWidth: 100,
                                responsive: 0,
                                field: 'delivered_quantity',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    return cell.getData().delivered_quantity ? cell.getData().delivered_quantity : '0'
                                },
                            },
                            {
                                title: 'Request Date',
                                minWidth: 150,
                                responsive: 0,
                                field: 'issue_date',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter: "textarea",
                            },
                            {
                                title: 'Status',
                                minWidth: 100,
                                field: 'active_status',
                                hozAlign: 'left',
                                vertAlign: 'left',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    if (cell.getData().active_status == 1) {

                                        return '<div class="flex items-center lg:justify-center text-theme-1">Available</div>'

                                    } else if (cell.getData().active_status == 2) {

                                        return '<div class="flex items-center lg:justify-center text-theme-3">Sold</div>'

                                    } else if (cell.getData().active_status == 3) {

                                        return '<div class="flex items-center lg:justify-center text-theme-6">Cancelled</div>'

                                    }
                                }
                            },

                        ],
                        renderComplete() {
                            feather.replace({
                                'stroke-width': 1.5
                            })
                        }
                    })

                    sessionStorage.setItem('stock_department_requset_filters', JSON.stringify(outward_filters))
                }, 50);

            }

        }
        // Get Product Details
        const sizeDetails = async () => {

            const params = {};

            let promise = axios({
                url: "api/master/dp_size",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.watt_details = data.items

                })
                .catch(_error => {

                    page_data.watt_details = []

                });
        }
        const get_pow_details = async (item) => {

          if(item.watt_id != '0' && item.product_type != '0') {

            // Get OW Details
            const params = {
              watt_id: window.btoa(item.watt_id),
              product_type: window.btoa(item.product_type)
            };

            let promise = axios({
                url: form_data.pow_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    item.pow_items = data.items
                    page_data.pow_id = '0'

                })
                .catch(_error => {

                    item.pow_items = []
                    page_data.pow_id = '0'

                });
          } else {
            item.pow_items = []
            page_data.pow_id = '0'
          }

        }

        const validate_quantity = (item) => {

          if(parseFloat(item.cr_quantity ? item.cr_quantity : 0) < parseFloat(item.sold_qty ? item.sold_qty : 0)) {

            item.sold_qty = '';
          }

          let total_module = 0

          form_data.pow_details.forEach(element => {

            total_module += parseFloat(element.sold_qty ? element.sold_qty : 0);

          });

          form_data.total_modules = total_module
        }

        const set_module_items = (item) => {


          item.pow_items.forEach(element => {
            if(item.pow_id == element.id) {
              item.cr_quantity = element.cr_quantity

              validate_quantity(item)
              return;
            }
          });


        }
        // Add Product Field
        const add_new_request_field = async () => {

            page_data.count_ids += 1;
            form_data.value.push({
                id: 1,
                watt_id: '0',
                product_type: '0',
                cr_quantity: '',
                sold_qty: '',
                pow_id: '0',
                pow_items: [],
            });

        }
        // Remove Request From Pannel
        const remove_request_field = async (item) => {

            swal({
                title: "Are you sure?",
                text: "You are about to remove record!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = form_data.value.length;
                    while (i--) {
                        if (form_data.value[i]["id"] == item.id) {
                            form_data.value.splice(i, 1);
                            form_data.value = form_data.value.filter(function () {
                                return true;
                            });
                            console.warn(form_data.value)
                            break
                        }
                    }
                }
            });

        }

        // Sell Stocks
        const release_stock = async () => {
            if (form_data.pow_details.length == 0) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fields are Empty!",
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                })
                return
            }
            // Validate Fileds
            let purchase_detail_length = form_data.pow_details.length

            let is_empty = false

            // Validate Records

            if (form_data.party_id == '0') {
                $('#party_id').addClass('border border-theme-6');
                is_empty = true;
            } else {
                $('#party_id').removeClass('border border-theme-6');
            }

            if (form_data.sell_date == '0') {
                $('#sell_date').addClass('border border-theme-6');
                is_empty = true;
            } else {
                $('#sell_date').removeClass('border border-theme-6');
            }

            if (form_data.total_modules == '' || form_data.total_modules == 0) {
                $('#total_modules').addClass('border border-theme-6');
                is_empty = true;
            } else {
                $('#total_modules').removeClass('border border-theme-6');
            }

            for (let i = 0; i < purchase_detail_length; i++) {

                if (form_data.pow_details[i].watt_id == '0') {
                    $('#watt_id_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#watt_id_' + i).removeClass('border border-theme-6');
                }
                if (form_data.pow_details[i].product_type == '0') {
                    $('#product_type_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#product_type_' + i).removeClass('border border-theme-6');
                }
                if (form_data.pow_details[i].sold_qty == '') {
                    $('#sold_qty_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#sold_qty_' + i).removeClass('border border-theme-6');
                }
                if (form_data.pow_details[i].pow_id == '0') {
                    $('#pow_id_' + i).addClass('border-theme-6');
                    is_empty = true;
                } else {
                    $('#pow_id_' + i).removeClass('border-theme-6');
                }
            }

            if (is_empty == true) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fields Are Empty!",
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                })
                return
            }

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Send Request to Stock Department!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    form_data.loading = true

                    let promise = axios({
                        url: form_data.url,
                        method: "POST",
                        data: form_data,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.status == "success") {
                                swal.fire({
                                    icon: "success",
                                    title: "Success!",
                                    text: data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                })
                                form_data.loading = false

                                  form_data.party_id = '0'
                                  form_data.sell_date = ''
                                  form_data.invoice_no = ''
                                  form_data.total_modules = ''
                                  form_data.pow_details = [{
                                    id: 1,
                                    watt_id: '0',
                                    product_type: '0',
                                    cr_quantity: '',
                                    sold_qty: '',
                                    pow_id: '0',
                                    pow_items: [],
                                }]

                                cash('#release-stock-modal').modal('hide')
                                initTabulator(page_data.stock_type)

                            } else {
                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                form_data.loading = false

                            }
                        })
                        .catch(_error => {
                            form_data.loading = false

                        });

                }
            });
        }

        // Get Outward Party Details
    const get_party_details = async () => {

      let promise_url = "api/master/dp_outward_party";
      let params = {}

      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {
        page_data.party_details = data.items
      })
      .catch(_error => {
        page_data.party_details = []

      });
    }

        // On reset filter
        const resetStockFilters = () => {
            outward_filters.party_id = "0"
            outward_filters.watt_id = "0"
            outward_filters.pannel_type = "0"
            outward_filters.status = "0"
            outward_filters.request_code = ""
            outward_filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
            outward_filters.e_date = moment().format('YYYY-MM-DD')
            outward_filters.page_size = 1

            initTabulator(page_data.stock_type)
        }
        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'data.xlsx', {
                sheetName: 'Products'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {

            sizeDetails()
            get_party_details()
            initTabulator(page_data.stock_type)
            // initTabulatorOW()
        })

        return {
            tableRef,
            page_data,
            form_data,
            outward_filters,
            print_filters,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            initTabulator,
            get_pow_details,
            resetStockFilters,
            add_new_request_field,
            remove_request_field,
            userDetails,
            release_stock,
            set_module_items,
            validate_quantity
        }
    }
})
</script>

<style scoped>
.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 700px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
