<template>
  <div class="grid grid-cols-12 gap-4 border-b-1">
    <div class="col-span-12 2xl:col-span-9" :class="{'2xl:col-span-12' : ![1].includes(user_details.user_type)}" v-if="[1,2,3,4].includes(user_details.user_type)">
      <div class="grid grid-cols-12 gap-4">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">General Report</h2>
            <a
              href="#" @click="get_details"
              class="ml-auto flex items-center text-theme-1 dark:text-theme-10"
            >
              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Reload Data
            </a>
          </div>
          <div class="grid grid-cols-12 gap-4 mt-5" v-if="[1,4].includes(user_details.user_type)">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex justify-between">
                    <div>
                      <Tippy
                        tag="div"
                        class="cursor-pointer text-2xl font-medium leading-8"
                        content="Total Duty Requested Quantity"
                      >
                      {{ $h.myFloat(page_data.duty_pending)}}
                      </Tippy>
                    </div>
                    <div><ClockIcon class="report-box__icon text-theme-12" /></div>
                  </div>
                  <div class="text-base text-gray-600 mt-1">Duty Requested</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex justify-between">
                    <div>
                      <Tippy
                        tag="div"
                        class="cursor-pointer text-2xl font-medium leading-8"
                        content="Total Duty Paid Transactions && Waiting for OOC"
                      >
                      {{ $h.myFloat(page_data.ooc_pending)}}
                      </Tippy>
                    </div>
                    <div><AlertCircleIcon class="report-box__icon text-theme-11" /></div>
                  </div>
                  <div class="text-base text-gray-600 mt-1">OOC Waiting</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex justify-between">
                    <div>
                      <Tippy
                        tag="div"
                        class="cursor-pointer text-2xl font-medium leading-8"
                        content="OOC Done Transactions`"
                      >
                      {{ $h.myFloat(page_data.duty_paid)}}
                      </Tippy>
                    </div>
                    <div><CreditCardIcon class="report-box__icon text-theme-9" /></div>
                  </div>
                  <div class="text-base text-gray-600 mt-1">OOC Done</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex justify-between">
                    <div>
                      <Tippy
                        tag="div"
                        class="cursor-pointer text-2xl font-medium leading-8"
                        content="Duty Paid transactions"
                      >
                      {{ $h.myFloat(page_data.duty_paid)}}
                      </Tippy>
                    </div>
                    <div><SendIcon class="report-box__icon text-theme-9" /></div>
                  </div>
                  <div class="text-base text-gray-600 mt-1">Duty Paid</div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-4 mt-10" v-if="[1,2,3].includes(user_details.user_type)">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex justify-between">
                    <div>
                      <Tippy
                        tag="div"
                        class="cursor-pointer text-2xl font-medium leading-8"
                        content="Total Duty Requested Quantity"
                      >
                      {{ $h.myFloat(page_data.module_stocks)}}
                      </Tippy>
                    </div>
                    <div><ArchiveIcon class="report-box__icon text-theme-12" /></div>
                  </div>
                  <div class="text-base text-gray-600 mt-1">Total Module Stock</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex justify-between">
                    <div>
                      <Tippy
                        tag="div"
                        class="cursor-pointer text-2xl font-medium leading-8"
                        content="Total Duty Paid Transactions && Waiting for OOC"
                      >
                      {{ $h.myFloat(page_data.delivered_modules)}}
                      </Tippy>
                    </div>
                    <div><ShoppingCartIcon class="report-box__icon text-theme-11" /></div>
                  </div>
                  <div class="text-base text-gray-600 mt-1">Delivered Module</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex justify-between">
                    <div>
                      <Tippy
                        tag="div"
                        class="cursor-pointer text-2xl font-medium leading-8"
                        content="OOC Done Transactions`"
                      >
                      {{ $h.myFloat(page_data.returned_stocks)}}
                      </Tippy>
                    </div>
                    <div><CornerDownLeftIcon class="report-box__icon text-theme-9" /></div>
                  </div>
                  <div class="text-base text-gray-600 mt-1">Returned Stocks</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex justify-between">
                    <div>
                      <Tippy
                        tag="div"
                        class="cursor-pointer text-xl font-medium leading-8 whitespace-nowrap"
                        content="Duty Paid transactions"
                      >
                      {{ page_data.last_invoice}}
                      </Tippy>
                    </div>
                    <div><InboxIcon class="report-box__icon text-theme-9" /></div>
                  </div>
                  <div class="text-base text-gray-600 mt-1">Last Invoice</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-6 mt-8 hidden">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Sales Report</h2>
            <div
              class="sm:ml-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300"
            >
              <CalendarIcon
                class="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-3 left-0"
              />
              <Litepicker
                v-model="salesReportFilter"
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control sm:w-56 box pl-10"
              />
            </div>
          </div>
          <div class="intro-y box p-5 mt-12 sm:mt-5">
            <div class="flex flex-col xl:flex-row xl:items-center">
              <div class="flex">
                <div>
                  <div
                    class="text-theme-19 dark:text-gray-300 text-lg xl:text-xl font-medium"
                  >
                    $15,000
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    This Month
                  </div>
                </div>
                <div
                  class="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-5"
                ></div>
                <div>
                  <div
                    class="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium"
                  >
                    $10,000
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    Last Month
                  </div>
                </div>
              </div>
              <div class="dropdown xl:ml-auto mt-5 xl:mt-0">
                <button
                  class="dropdown-toggle btn btn-outline-secondary font-normal"
                  aria-expanded="false"
                >
                  Filter by Category
                  <ChevronDownIcon class="w-4 h-4 ml-2" />
                </button>
                <div class="dropdown-menu w-40">
                  <div
                    class="dropdown-menu__content box dark:bg-dark-1 p-2 overflow-y-auto h-32"
                  >
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >PC & Laptop</a
                    >
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >Smartphone</a
                    >
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >Electronic</a
                    >
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >Photography</a
                    >
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >Sport</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="report-chart">
              <ReportLineChart :height="169" class="mt-6" />
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Weekly Top Seller -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8 hidden">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Weekly Top Seller</h2>
            <a href="" class="ml-auto text-theme-1 dark:text-theme-10 truncate"
              >Show More</a
            >
          </div>
          <div class="intro-y box p-5 mt-5">
            <ReportPieChart :height="300" class="mt-3" />
            <div class="mt-8">
              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <span class="truncate">17 - 30 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">62%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                <span class="truncate">31 - 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">33%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                <span class="truncate">>= 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">10%</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Weekly Top Seller -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8 hidden">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Sales Report</h2>
            <a href="" class="ml-auto text-theme-1 dark:text-theme-10 truncate"
              >Show More</a
            >
          </div>
          <div class="intro-y box p-5 mt-5">
            <ReportDonutChart :height="300" class="mt-3" />
            <div class="mt-8">
              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <span class="truncate">17 - 30 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">62%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                <span class="truncate">31 - 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">33%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                <span class="truncate">>= 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">10%</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Official Store -->
        <div class="col-span-12 xl:col-span-8 mt-6" v-if="[1,2,4].includes(user_details.user_type)">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Duty Quantity & Amounts</h2>
          </div>
          <div class="intro-y box p-5 mt-12 sm:mt-5">
            <div>
              <!-- 250 Official stores in 21 countries, click the marker to see
              location details. -->
            </div>
            <div class="grid grid-cols-12 gap-4 my-10">
              <div class="col-span-12 sm:col-span-6 xl:col-span-6 intro-y">
                <div class="report-box zoom-in">
                  <div class="box p-5">
                    <div class="flex justify-between">
                      <div>
                        <Tippy
                          tag="div"
                          class="cursor-pointer text-2xl font-medium leading-8"
                          content="Total Duty Requested Pending Quantity"
                        >
                        {{ $h.myFloat(page_data.duty_quantity)}}
                        </Tippy>
                      </div>
                      <div><BookIcon class="report-box__icon text-theme-9" /></div>
                    </div>
                    <div class="text-base text-gray-600 mt-1">Duty Pending Quantity</div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6 xl:col-span-6 intro-y">
                <div class="report-box zoom-in">
                  <div class="box p-5">
                    <div class="flex justify-between">
                      <div>
                        <Tippy
                          tag="div"
                          class="cursor-pointer text-2xl font-medium leading-8"
                          content="Total Duty Pending Amount"
                        >
                        {{ $h.myFloat(page_data.duty_amount)}}
                        </Tippy>
                      </div>
                      <div><InfoIcon class="report-box__icon text-theme-11" /></div>
                    </div>
                    <div class="text-base text-gray-600 mt-1">Duty Pending Amount</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Official Store -->
        <!-- BEGIN: Weekly Best Sellers -->
        <div class="col-span-12 xl:col-span-4 mt-6" v-if="[1,2,4].includes(user_details.user_type)">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              Module Current Stocks
            </h2>
          </div>
          <div class="mt-5">
            <div
              v-for="(faker, fakerKey) in page_data.final_module_stocks"
              :key="fakerKey"
              class="intro-y"
            >
              <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                <!-- <div
                  class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                > -->
                  <!-- <img
                    alt="Icewall Tailwind HTML Admin Template"
                    :src="require(`@/assets/images/${faker.photos[0]}`).default"
                  /> -->
                <!-- </div> -->
                <div class="ml-2 mr-auto">
                  <div class="font-medium">{{ faker.watt }}</div>
                  <div class="text-gray-600 text-xs mt-0.5">
                    {{ faker.product_type == 1 ? "Poly" : (faker.product_type == 2 ? "MBB" : (faker.product_type == 4 ? "TopCon" : "All")) }}
                  </div>
                </div>
                <div
                  class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium"
                >
                {{ faker.cr_quantity }} Modules
                </div>
              </div>
            </div>
            <router-link
              to="/stock/outward-stock"
              class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
              >View More</router-link
            >
          </div>
        </div>
        <!-- END: Weekly Best Sellers -->
        <!-- BEGIN: General Report -->
        <div class="col-span-12 grid grid-cols-12 gap-4 mt-5" v-if="[1,2,4].includes(user_details.user_type)">
          <div class="col-span-12 intro-y flex items-center">
            <h2 class="text-lg font-medium mr-5">
              Estimated Module Production Details
            </h2>
          </div>
          <div class="col-span-12 intro-y">
            <div class="box p-5">
              <table class="w-full text-center">
                <thead>
                  <tr class="bg-theme-14">
                    <th class="border-b p-2 py-3 dark:border-dark-5 whitespace-nowrap">#</th>
                    <th class="border-b p-2 py-3 dark:border-dark-5 whitespace-nowrap">Watt</th>
                    <th class="border-b p-2 py-3 dark:border-dark-5 whitespace-nowrap">Module Type</th>
                    <th class="border-b p-2 py-3 dark:border-dark-5 whitespace-nowrap">Est Total Module</th>
                    <th class="border-b p-2 py-3 dark:border-dark-5">Est Duty Paid Module</th>
                  </tr>
                </thead>
                <tbody class="text-center" v-if="page_data.con_items.length > 0">
                  <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in page_data.con_items" :key="index">
                    <td class="border-b py-2">{{ index + 1 }}</td>
                    <td class="border-b py-2">{{ item.watt }}</td>
                    <td class="border-b py-2">{{ item.product_type == 1 ? "Poly" : (item.product_type == 2 ? "MBB" : (item.product_type == 4 ? "TopCon" : "All")) }}</td>
                    <td class="border-b py-2">{{ item.quantity_all }}</td>
                    <td class="border-b py-2">{{ item.quantity_paid }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
      </div>
    </div>
    <div class="col-span-12 2xl:col-span-3" v-if="[1].includes(user_details.user_type)">
      <div class="2xl:border-l border-theme-5 -mb-10 pb-10">
        <div class="2xl:pl-3 grid grid-cols-12 gap-4">
          <!-- BEGIN: Transactions -->
          <div
            class="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12 mt-3 2xl:mt-8"
          >
            <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">Active OW Modules</h2>
            </div>
            <div class="mt-5">
              <div
                v-for="(faker, fakerKey) in page_data.last_ow"
                :key="fakerKey"
                class="intro-x"
              >
                <div class="box px-5 py-3 mb-3 flex items-center zoom-in">
                  <!-- <div
                    class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden"
                  > -->
                    <!-- <img
                      alt="Icewall Tailwind HTML Admin Template"
                      :src="
                        require(`@/assets/images/${faker.photos[0]}`).default
                      "
                    /> -->
                  <!-- </div> -->
                  <div class="mr-auto">
                    <div class="font-medium">
                      {{ faker.request_code }} ({{ faker.product_type == 1 ? "Poly" : (faker.product_type == 2 ? "MBB" : (faker.product_type == 4 ? "TopCon" : "All")) }})
                    </div>
                    <div class="text-gray-600 text-xs mt-0.5">
                      {{ faker.watt }}
                    </div>
                    <div class="text-gray-600 text-xs mt-0.5">
                      {{ faker.issue_date }}
                    </div>
                  </div>
                  <div class="text-theme-9"
                  >
                    {{ faker.quantity }}
                  </div>
                </div>
              </div>
              <router-link
                to="/stock/outward-request/list"
                class="intro-x w-full block text-center rounded-md py-3 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
                >View More</router-link
              >
            </div>
          </div>
          <!-- END: Transactions -->
          <!-- BEGIN: Recent Activities -->
          <div
            class="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12 mt-3"
          >
            <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                Recent Activities
              </h2>
              <router-link
                to="/user-operation"
                class="ml-auto text-theme-1 dark:text-theme-10 truncate"
                >Show More</router-link
              >
            </div>
            <div class="report-timeline mt-5 relative">
              <div class="intro-x relative flex items-center mb-3" v-for="(faker, fakerKey) in page_data.recent_activities"
                :key="fakerKey">
                <div class="report-timeline__image">
                  <div
                    class="w-5 h-5 flex-none overflow-hidden"
                  >
                  </div>
                </div>
                <div class="box px-5 py-3 ml-4 flex-1 zoom-in">
                  <div class="flex items-center">
                    <div class="font-medium">
                      <span v-if="faker.trans_type?.action_group == 'INWARD'">
                        <span v-if="faker.trans_type?.action_name == 'IMPORT'">{{ faker.import?.transaction_no ?? '-' }}
                        </span>
                        <span v-else-if="faker.trans_type?.action_name == 'DTA'"> {{ faker.dta?.transaction_no ?? '-' }}
                        </span>
                      </span>
                      <span v-else-if="faker.trans_type?.action_group == 'DUTY'">{{ faker.duty?.transaction_no ?? '-' }}</span>
                      <span v-else-if="faker.trans_type?.action_group == 'O/W'"> {{ faker.ow?.transaction_no ?? '-' }}</span>
                      <span v-else-if="faker.trans_type?.action_group == 'RELEASE QTY'">{{ faker.release?.transaction_no ?? '-' }}</span>
                    </div>
                    <div class="text-xs text-gray-500 ml-auto">{{(faker.creator.first_name ? faker.creator.first_name : '-') }}</div>
                  </div>
                  <div class="flex items-center">
                    <div class="text-gray-600 mt-1">
                      {{faker.trans_type.action_name + ' ' + faker.trans_type.action_type}}</div>
                    </div>
                    <div class="text-xs text-gray-500 ml-auto">{{(faker.created_time ? faker.created_time : '-') }}</div>

                  </div>
              </div>
            </div>
          </div>
          <!-- END: Recent Activities -->
        </div>
      </div>
    </div>
  </div>
  <!-- BEGIN: Weekly Top Products -->
  <div class="col-span-12 mt-6" v-if="[1,2].includes(user_details.user_type)">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              Import Purchase Month Wise
            </h2>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">Month</th>
                  <th class="whitespace-nowrap">Total Purchase</th>
                  <th class="whitespace-nowrap">Total Amount</th>
                  <th class="whitespace-nowrap">Total Tax</th>
                  <!-- <th class="whitespace-nowrap">Duty Pending AMT</th>
                  <th class="whitespace-nowrap">Duty Paid AMT</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(faker, fakerKey) in page_data.import_purchases"
                  :key="fakerKey"
                  class="intro-x"
                >
                  <td>
                    <div class="font-medium whitespace-nowrap">{{
                      faker.month
                    }}</div>
                  </td>
                  <td>
                    <div class="font-medium whitespace-nowrap">{{
                      faker.total_purchases
                    }}</div>
                  </td>
                  <td>
                    <div class="font-medium whitespace-nowrap">{{
                      $h.myFloat(faker.total_amount)
                    }}</div>
                  </td>
                  <td>
                    <div class="font-medium whitespace-nowrap">{{
                      $h.myFloat(faker.duty_amount)
                    }}</div>
                  </td>
                  <!-- <td>
                    <div class="font-medium whitespace-nowrap">{{
                      $h.myFloat(faker.duty_pending_amount)
                    }}</div>
                  </td>
                  <td>
                    <div class="font-medium whitespace-nowrap">{{
                      $h.myFloat(faker.duty_paid_amount)
                    }}</div>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- END: Weekly Top Products -->
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, provide, onMounted, computed } from 'vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import ReportMap from '@/components/report-map/Main.vue'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
import SimpleLineChart1 from '@/components/simple-line-chart-1/Main.vue'
import axios from 'axios'
import { BASE_URL } from '@/constant/config.js'
import { CheckSquareIcon } from '@zhuowenli/vue-feather-icons'
import { helper as $h } from '@/utils/helper'
import store from '@/store'

export default defineComponent({
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart,
    ReportMap,
    ReportDonutChart1,
    SimpleLineChart1
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const salesReportFilter = ref()
    const importantNotesRef = ref()

    const page_data = reactive({
      "duty_pending" : "",
      "ooc_pending" : "",
      "duty_paid" : "",
      "duty_quantity" : "",
      "duty_amount" : "",
      "module_stocks" : "",
      "delivered_modules" : "",
      "returned_stocks" : "",
      "last_invoice" : "",
      "last_ow" : [],
      "recent_activities" : [],
      "final_module_stocks" : [],
      "con_items" : [],
      "import_purchases" : []
    })

    const get_details = () => {
      let promise_url = "api/dashboard";
      let promise = axios({
        url: promise_url,
        method: "POST",
        // data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.duty_pending = data.items.duty_pending
          page_data.ooc_pending = data.items.ooc_pending
          page_data.duty_paid = data.items.duty_paid
          page_data.duty_quantity = data.items.duty_quantity
          page_data.duty_amount = data.items.duty_amount
          page_data.module_stocks = data.items.module_stocks
          page_data.delivered_modules = data.items.delivered_modules
          page_data.returned_stocks = data.items.returned_stocks
          page_data.last_invoice = data.items.last_invoice
          page_data.last_ow = data.items.last_ow
          page_data.recent_activities = data.items.recent_activities
          page_data.final_module_stocks = data.items.final_module_stocks
          page_data.con_items = data.items.con_items
          page_data.import_purchases = data.items.import_purchases
        })
        .catch(_error => {
          page_data.duty_pending = "";
          page_data.ooc_pending = "";
          page_data.duty_paid = "";
          page_data.duty_qualtity = "";
          page_data.duty_amount = "";
          page_data.module_stocks = "";
          page_data.delivered_modules = "";
          page_data.returned_stocks = "";
          page_data.last_invoice = "";
          page_data.last_ow = [];
          page_data.recent_activities = [];
          page_data.final_module_stocks = [];
          page_data.con_items = [];
          page_data.import_purchases = [];

        });
    }

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    onMounted(() => {
      get_details()
    })

    return {
      page_data,
      user_details,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      get_details
    }
  }
})
</script>
