<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">OW Stock Report</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2 mt-5">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="input-quantity" class="form-label py-1">OW NO</label>
          <input
            id="input-quantity"
            class="form-control"
            placeholder="OW NO"
            v-model="outward_filters.request_code"
            type="text">
        </div>
        <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Select Party</label>
          <TomSelect
            v-model="outward_filters.party_id"
              :options="{
                placeholder: 'Select Party'
              }"
              class="w-full"
              name="Party"
              id="pos-form-2"
          >
          <option value="0">Select Party</option>
          <option v-for="item in pagedata.party_details" :key="item.id" :value="item.id">{{ item.companey_name}}</option>
          </TomSelect>
        </div> -->
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Select Watt</label>
          <TomSelect
            v-model="outward_filters.watt_id"
              :options="{
                placeholder: 'Select Watt'
              }"
              class="w-full"
              name="Watt"
              id="pos-form-2"
          >
          <option value="0">Select Watt</option>
          <option v-for="item in pagedata.watt_details" :key="item.id" :value="item.id">{{ item.watt}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Module Type</label>
          <TomSelect
            v-model="outward_filters.pannel_type"
              :options="{
                placeholder: 'Select Module Type'
              }"
              class="w-full"
              name="Module Type"
              id="pos-form-2"
          >
          <option value="0">Select Module Type</option>
          <option value="1">Poly</option>
          <option value="2">MBB</option>
          <option value="4">TopCon</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">

          <label for="update-profile-form-6" class="form-label py-1"
            >Select Start Date</label
          >
          <input
            type= "date"
            id="update-profile-form-13"

            class="form-control"
            placeholder=""
            v-model="outward_filters.s_date"
          />
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">

          <label for="update-profile-form-6" class="form-label py-1"
            >Select End Date</label
          >
          <input
            type= "date"
            id="update-profile-form-13"

            class="form-control"
            placeholder=""
            v-model="outward_filters.e_date"
          />
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-7">
            <button
            @click="resetStockFilters"
            class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-7">
            <button
            @click="get_report_details()"
            class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Consumptions</div>
              </div>
              </div>
            </div>
          </div>
          <div v-else>

            <div class="border mt-5 p-0 shadow-xl">
              <div class="overflow-x-auto m-0 p-0">
                <table class="w-full text-xs text-center">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap">NO.</th>
                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap">OW NO</th>
                      <!-- <th class="p-2 border dark:border-dark-5 whitespace-nowrap">Party</th> -->
                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap">Watt</th>
                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap">Type</th>
                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap">Module</th>
                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap">Invoice No</th>
                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap">Stock Date</th>
                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap">Selling Details</th>
                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap">Material Purchase Details</th>
                    </tr>
                  </thead>
                  <tbody class="text-center" v-if="pagedata.data_items.length > 0">
                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in pagedata.data_items" :key="index">
                      <td class="border-b border-r py-2">{{ index + 1 }}</td>
                      <td class="border-b border-r py-2">{{ item.request_code }}</td>
                      <!-- <td class="border-b border-r py-2">{{ item.companey_name }}</td> -->
                      <td class="border-b border-r py-2">{{ item.watt }}</td>
                      <td class="border-b border-r py-2">{{ item.product_type == 1 ? "Poly" : (item.product_type == 2 ? "MBB" : (item.product_type == 4 ? "TopCon" : "All")) }}</td>
                      <td class="border-b border-r py-2">{{ item.final_quantity ? $h.myFloat(item.final_quantity) : '-' }}</td>
                      <td class="border-b border-r py-2">{{ item.ref_no ? item.ref_no : '-' }}</td>
                      <td class="border-b border-r py-2">{{ item.outward_date ? item.outward_date : '-' }}</td>
                      <td class="border-b border-r p-0 m-0 align-top">
                        <span v-if="item.party_details.length > 0">
                          <table>
                            <tr class="bg-theme-14">
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Party Name</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Invoice No</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Quantity</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Est Duty</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Sell Date</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Entry Date</th>
                            </tr>
                            <tr v-for="(sub_item,inx) in item.party_details" :key="inx">
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.companey_name ? sub_item.companey_name : '-' }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.invoice_no ? sub_item.invoice_no : '-' }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.quantity ? sub_item.quantity : '-' }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.duty_amount ? sub_item.duty_amount : '-' }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.sell_date ? sub_item.sell_date : '-' }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.issue_date ? sub_item.issue_date : '-' }}</td>
                            </tr>
                          </table>
                        </span>
                        <span v-else>
                          -
                        </span>
                      </td>
                      <td class="border-b border-r p-0 m-0">
                        <span v-if="item.purchase_details.length > 0">
                          <table>
                            <tr class="bg-theme-14">
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Purchase Code</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Purchase Type</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Material</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Specification</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Type</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Quantity</th>
                              <th class="p-2 border dark:border-dark-5">Tax Details</th>
                              <!-- <th class="p-2 border dark:border-dark-5">Selling Details</th> -->
                            </tr>
                            <tr v-for="(sub_item,inx) in item.purchase_details" :key="inx">
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.purchase_code }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.purchase_type == 1 ? "Import" : "DTA" }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.product_name }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.description }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.product_type == 1 ? "Poly" : (sub_item.product_type == 2 ? "MBB" : (sub_item.product_type == 4 ? "TopCon" : "All")) }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ $h.myFloat(sub_item.quantity) + ' ' + sub_item.uqc_name }}</td>
                              <td class="border dark:border-dark-5 p-0">
                                <span class="p-0" v-if="sub_item.tax_details && sub_item.tax_details.length > 0">
                                  <table>
                                    <tr class="bg-theme-14">
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Duty Code</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Quantity</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Duty Amount</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Ref NO</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">OOC NO</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-60">OOC Date</th>
                                    </tr>
                                    <tr v-for="(duty_item,inx) in sub_item.tax_details" :key="inx">
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ duty_item.duty_code }}</td>
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ duty_item.duty_request_qty ? $h.myFloat(duty_item.duty_request_qty) : '-' }}</td>
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ (duty_item.duty_amount_request ? $h.myFloat(duty_item.duty_amount_request) : '-') }}</td>
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ duty_item.ref_no }}</td>
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ duty_item.ooc_no }}</td>
                                      <td class="border py-2 dark:border-dark-5 w-60">{{ duty_item.ooc_date }}</td>
                                    </tr>
                                  </table>
                                </span>
                                <span v-else>
                                 {{ sub_item.tax_details }}
                                </span>
                              </td>
                            </tr>
                          </table>
                        </span>
                        <span v-else>
                          -
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="pagedata.data_items.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, reactive,onMounted } from 'vue'
import axios from 'axios'
import { BASE_URL } from '@/constant/config.js'
import moment from 'moment'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      data_items: [],
      watt_details: [],
      party_details: [],
      loading: false,
    })
    const outward_filters = reactive({
      party_id: "0",
      watt_id: "0",
      pannel_type: "0",
      request_code: "",
      s_date:moment().startOf('month').format('YYYY-MM-DD'),
      e_date:moment().format('YYYY-MM-DD'),
      page_size: '1'
    })
    const print_filters = reactive({
      party_id: "0",
      watt_id: "0",
      pannel_type: "0",
      request_code: "",
      s_date:moment().startOf('month').format('YYYY-MM-DD'),
      e_date:moment().format('YYYY-MM-DD')
    })

    // Get Report Details
    const get_report_details = async () => {

      print_filters.party_id = outward_filters.party_id
      print_filters.watt_id = outward_filters.watt_id
      print_filters.pannel_type = outward_filters.pannel_type
      print_filters.request_code = outward_filters.request_code
      print_filters.s_date = outward_filters.s_date
      print_filters.e_date = outward_filters.e_date

      let params = {
        "party_id": print_filters.party_id == "0" ? "" : window.btoa(print_filters.party_id),
        "watt_id": print_filters.watt_id == "0" ? "" : window.btoa(print_filters.watt_id),
        "pannel_type": print_filters.pannel_type == "0" ? "" : window.btoa(print_filters.pannel_type),
        "request_code": print_filters.request_code == "" ? "" : window.btoa(print_filters.request_code),
        "s_date": print_filters.s_date == "" ? "" : window.btoa(print_filters.s_date),
        "e_date": print_filters.e_date == "" ? "" : window.btoa(print_filters.e_date)
      }

      pagedata.loading = true

      let promise = axios({
        url: "api/report/party-wise-outward-report",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.data_items = data.items
        pagedata.loading = false

        sessionStorage.setItem('party_wise_outward_report',JSON.stringify(print_filters))

      })
      .catch(_error => {

        pagedata.data_items = []
        pagedata.loading = false

      });

    }

   // Get Product Details
   const sizeDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_size",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.watt_details = data.items

      })
      .catch(_error => {

        pagedata.watt_details = []

      });
    }

    // Get Material Details
    const partyDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_outward_party",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.party_details = data.items

      })
      .catch(_error => {

        pagedata.party_details = []

      });
    }

    // On reset filter
    const resetStockFilters = () => {
      outward_filters.party_id = "0"
      outward_filters.watt_id = "0"
      outward_filters.pannel_type = "0"
      outward_filters.request_code = ""
      outward_filters.s_date =moment().startOf('month').format('YYYY-MM-DD')
      outward_filters.e_date =moment().format('YYYY-MM-DD')
    }

    onMounted(() => {

      let filter_items = sessionStorage.getItem('party_wise_outward_report') != null ? JSON.parse(sessionStorage.getItem('party_wise_outward_report')) : null

      if(filter_items != null) {
        outward_filters.party_id = filter_items.party_id
        outward_filters.watt_id = filter_items.watt_id
        outward_filters.pannel_type = filter_items.pannel_type
        outward_filters.request_code = filter_items.request_code
        outward_filters.s_date = filter_items.s_date
        outward_filters.e_date = filter_items.e_date

      }
      sizeDetails()
      partyDetails()
      get_report_details()
    })


    return {
      pagedata,outward_filters,print_filters,
      get_report_details,
      resetStockFilters,
    }
  }
})
</script>
