<template>
<div>
    <div class="intro-y flex items-center mt-5">
        <h2 class="text-lg font-medium mr-auto">DTA Purchase</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div id="input" class="p-5">
                    <div class="preview">
                        <div>
                            <div class="sm:grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-2">
                                <div>
                                    <label for="regular-form-1" class="form-label">Gst Invoice No <span class="text-primary-3"> *</span></label>
                                    <div>

                                        <input v-model="formData.gst_invoice_no" id="gst_invoice_no" autocomplete="off" type="text" class="form-control" placeholder="Gst Invoice No" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.gst_invoice_no">{{ pagedata.errors.gst_invoice_no[0] }}</span>
                                </div>
                                <div>
                                    <div>
                                        <label for="regular-form-1" class="form-label">Gst Invoice Date <span class="text-primary-3"> *</span></label>
                                        <div>
                                            <input v-model="formData.gst_invoice_date" id="gst_invoice_date" type="date" autocomplete="off" class="form-control" />
                                        </div>
                                        <span class="text-primary-3" v-if="pagedata.errors.gst_invoice_date">{{ pagedata.errors.gst_invoice_date[0] }}</span>
                                    </div>
                                </div>
                                <div>
                                    <label for="regular-form-1" class="form-label">Po No <span class="text-primary-3"> *</span></label>
                                    <div>

                                        <input v-model="formData.po_no" id="po_no" autocomplete="off" type="text" class="form-control" placeholder="Po No" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.po_no">{{ pagedata.errors.po_no[0] }}</span>
                                </div>
                                <div>
                                    <div>
                                        <label for="regular-form-1" class="form-label">Po Date <span class="text-primary-3"> *</span></label>
                                        <div>
                                            <input v-model="formData.po_date" id="po_date" type="date" autocomplete="off" class="form-control" />
                                        </div>
                                        <span class="text-primary-3" v-if="pagedata.errors.po_date">{{ pagedata.errors.po_date[0] }}</span>
                                    </div>
                                </div>
                                <div>
                                    <label for="regular-form-2" class="form-label">Import Station <span class="text-primary-3"> *</span></label>
                                    <div id="station_id" class="p-0 m-0">
                                        <TomSelect v-model="formData.station_id" :options="{
                            placeholder: 'Select Station'
                          }" class="w-full">
                                            <option value="">Select Station</option>
                                            <option v-for="dept in pagedata.stations" :key="dept.id" :value="dept.id">{{ dept.station_name }}</option>
                                        </TomSelect>

                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.station_id">{{ pagedata.errors.station_id[0] }}</span>
                                </div>
                                <div class="md:col-span-2 sm:col-span-1">
                                    <label for="regular-form-2" class="form-label">Company <span class="text-primary-3"> *</span></label>
                                    <div id="company_id" class="p-0 m-0">
                                        <TomSelect v-model="formData.company_id" :options="{
                            placeholder: 'Select Companey'
                          }" class="w-full">
                                            <option value="">Select Companey</option>
                                            <option v-for="dept in pagedata.companies" :key="dept.id" :value="dept.id">{{ dept.companey_name }}</option>
                                        </TomSelect>

                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.company_id">{{ pagedata.errors.company_id[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-2" class="form-label">Insurance No</label>
                                    <div>

                                        <input v-model="formData.insurance_no" id="insurance_no" type="text" autocomplete="off" class="form-control" placeholder="Insurance No" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.insurance_no">{{ pagedata.errors.insurance_no[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-2" class="form-label">Insurance</label>
                                    <div>

                                        <input v-model="formData.insurance" id="insurance" type="number" @change="calculateTotalAccessable" autocomplete="off" class="form-control" placeholder="Insurance" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.insurance">{{ pagedata.errors.insurance[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-2" class="form-label">Freight Type</label>
                                    <div>
                                        <TomSelect v-model="formData.freigth_type" :options="{
                            placeholder: 'Select Product'
                          }" @change="remove_freigth_value" class="w-full">
                                            <option value="2">Ex-W</option>
                                            <option value="4">FOB</option>
                                            <option value="5">CIF</option>
                                            <option value="1">Door Delivery</option>
                                            <option value="3">FOR</option>
                                        </TomSelect>

                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.freigth_type">{{ pagedata.errors.freigth_type[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-1" class="form-label">Freight</label>
                                    <div>

                                        <input id="freigth" type="number" v-model="formData.freigth" :disabled="formData.freigth_type == 1 || formData.freigth_type == 5" @change="calculateTotalAccessable" autocomplete="off" placeholder="Freight" class="form-control" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.freigth">{{ pagedata.errors.freigth[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-1" class="form-label">E Way Bill No <span class="text-primary-3" v-if="formData.total_with_tax_value > 50000"> *</span></label>
                                    <div>

                                        <input v-model="formData.eway_bill_no" autocomplete="off" type="text" class="form-control" id="eway_bill_no" placeholder="E Way Bill No" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.eway_bill_no">{{ pagedata.errors.eway_bill_no[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-2" class="form-label">E Way Bill Date <span class="text-primary-3" v-if="formData.total_with_tax_value > 50000"> *</span></label>
                                    <div>

                                        <input v-model="formData.eway_bill_date" id="eway_bill_date" type="date" autocomplete="off" class="form-control" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.eway_bill_date">{{ pagedata.errors.eway_bill_date[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-2" class="form-label">Ware Receipt Date-Time <span class="text-primary-3"> *</span></label>
                                    <div>

                                        <input v-model="formData.warehouse_receipt_time" id="warehouse_receipt_time" type="datetime-local" autocomplete="off" class="form-control" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.warehouse_receipt_time">{{ pagedata.errors.warehouse_receipt_time[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-2" class="form-label">Inward Date <span class="text-primary-3"> *</span></label>
                                    <div>

                                        <input v-model="formData.inward_date" id="inward_date" type="date" autocomplete="off" class="form-control" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.inward_date">{{ pagedata.errors.inward_date[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-2" class="form-label">Inward Amount</label>
                                    <div>

                                        <input v-model="formData.inward_value" id="inward_value" type="text" disabled autocomplete="off" class="form-control" placeholder="Inward Amount" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.inward_value">{{ pagedata.errors.inward_value[0] }}</span>
                                </div>
                                <div>
                                    <label for="regular-form-2" class="form-label">Accessable Amount</label>
                                    <div>

                                        <input v-model="formData.accessable_value" id="accessable_value" type="text" disabled class="form-control" autocomplete="off" placeholder="Accessable Amount" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.accessable_value">{{ pagedata.errors.accessable_value[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>

        <div class="intro-y col-span-12 pb-3">
            <div class="intro-y box p-3">
                <h2 class="font-medium text-base mr-auto">Purchase Products</h2>
                <table class="w-full border mt-3">
                    <thead>
                        <tr>
                            <th>Product Name <span class="text-primary-3"> *</span></th>
                            <th>Material Specification <span class="text-primary-3"> *</span></th>
                            <th>Product Type <span class="text-primary-3"> *</span></th>
                            <th>Note</th>
                            <th>Quantity <span class="text-primary-3"> *</span></th>
                            <th>UQC</th>
                            <!-- <th>Stock UQC</th> -->
                            <th>Per Unit Rate <span class="text-primary-3"> *</span></th>
                            <th>Total Amount <span class="text-primary-3"> *</span></th>
                        </tr>
                    </thead>
                    <tbody class="border" v-for="(plan,index) in formData.purchase_details" :key="index">
                        <tr>
                            <td>
                                <select class="form-select" v-model="plan.product_id" :id="'product_id_' + index" @change="onChangeProductId(plan, index)" aria-label="Default select example">
                                    <option :value="item.id" v-for="item in pagedata.products" :key="item.id">{{ item.product_name }}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select" v-model="plan.material_id" :id="'material_id_' + index" @change="getProductTaxDetails(plan, index)" aria-label="Default select example">
                                    <option :value="item.id" v-for="item in pagedata.material_details[index]" :key="item.id">{{ item.description }}</option>
                                </select>
                            </td>
                            <td>
                                <div>
                                    <TomSelect v-model="plan.product_type" @change="getProductTaxDetails(plan, index)" :options="{
                            placeholder: 'Select Product Type'
                          }" class="w-32">
                                        <!-- <option value="3">All</option> -->
                                        <option value="1" v-if="plan.product_type == 1">Poly</option>
                                        <option value="2" v-if="plan.product_type == 2">MBB</option>
                                        <option value="4" v-if="plan.product_type == 4">TopCon</option>
                                        <option value="3" v-if="plan.product_type == 3">Both</option>
                                    </TomSelect>

                                </div>
                            </td>
                            <td>
                              <input v-model="plan.note" type="text"
                              autocomplete="off"
                              class="form-control w-60" placeholder="Note">
                            </td>
                            <td>
                                <input v-model="plan.quantity" type="number" :id="'quantity_' + index" autocomplete="off" @change="calculateAccessableValue(plan, index)" class="form-control" placeholder="Quantity">
                            </td>
                            <td>
                                <select class="form-select w-24" v-model="plan.uqc_id" disabled aria-label="Default select example">
                                    <option :value="item.id" v-for="item in pagedata.uqcs" :key="item.id">{{ item.uqc_name }}</option>
                                </select>
                            </td>
                            <!-- <td>
                  <select
                      class="form-select"
                      v-model="plan.uqc_id_2"
                      disabled
                      aria-label="Default select example"
                    >
                    <option :value="item.id" v-for="item in pagedata.uqcs" :key="item.id">{{ item.uqc_name }}</option>
                  </select>
                </td> -->
                            <td>
                                <input v-model="plan.value_per_unit" type="number" autocomplete="off" :id="'value_per_unit_' + index" @change="calculateAccessableValue(plan, index)" class="form-control" placeholder="Per Unit">
                            </td>
                            <td>
                                <input v-model="plan.accessable_value" type="text" disabled :id="'accessable_value_' + index" class="form-control" placeholder="Total Amount">
                            </td>
                            <td>
                                <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="removeField(plan.id)">
                                    <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th :class="{ 'text-center font-bold' : plan.different_uqc == true }">
                                {{ plan.different_uqc ? 'Stock UQC' : ''}}
                            </th>
                            <th>
                                <div v-if="plan.different_uqc">

                                    <input v-model="plan.quantity_2" type="text" disabled class="form-control" placeholder="Stock Quantity">
                                </div>
                            </th>
                            <td>
                                <div v-if="plan.different_uqc">
                                    <select class="form-select w-24" v-model="plan.uqc_id_2" disabled aria-label="Default select example">
                                        <option :value="item.id" v-for="item in pagedata.uqcs" :key="item.id">{{ item.uqc_name }}</option>
                                    </select>

                                </div>
                            </td>
                            <th>Gst {{ plan.gst ? ' ('+plan.gst + '%) ' : '' }}</th>
                            <td><input v-model="plan.gst_value" type="text" disabled class="form-control" placeholder="Gst Amount"></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <td></td>
                            <th>Product Amount</th>
                            <td><input v-model="formData.inward_value" type="text" disabled class="form-control" placeholder="Product Amount"></td>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <td></td>
                            <th>Total Gst</th>
                            <td><input v-model="formData.tax_value" type="text" disabled class="form-control" placeholder="Tax Amount"></td>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <td></td>
                            <th>Total Amount</th>
                            <td><input v-model="formData.total_with_tax_value" type="text" disabled class="form-control" placeholder="Total Amount"></td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex items-center justify-between">
                    <div>
                        <button type="button" @click="AddProductField" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
                            Add Product
                        </button>

                    </div>
                    <div>

                        <button type="button" @click="cancelPurchase" class="btn btn-outline-secondary w-32 mr-1">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary w-32" :disabled="pagedata.submit" @click="SaveManager">
                            Save Details
                            <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                        </button>
                    </div>
                </div>
            </div>

        </div>

        <div class="intro-y col-span-12 pb-3">
            <div class="intro-y box p-3">
                <h2 class="font-medium text-base mr-auto">Purchase Duties</h2>
                <table class="w-full border mt-3" v-for="(item, index) in formData.purchase_details" :key="index">
                    <tr class="bg-gray-200 dark:bg-dark-1">
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="7">{{ index + 1 }}</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Product Name</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Material Specification</th>
                    </tr>
                    <tr>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.product_name }}</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.material_name }}</th>
                    </tr>
                    <tr class="bg-gray-100 dark:bg-dark-1">
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Quantity</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">UQC</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Product Amount</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Insurance</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Freight</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">CIF Amount</th>
                    </tr>
                    <tr>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }}</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.uqc_name }}</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value ? parseFloat(item.accessable_value).toFixed(2) : '-' }}</th>
                        <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.conversation_value && item.value_per_unit) ? (item.value_per_unit * item.conversation_value) : '' }}</th> -->
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.insurance ? parseFloat(item.insurance).toFixed(2) : '-' }}</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.freigth ? parseFloat(item.freigth).toFixed(2) : '-' }}</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.total_access_weight ? parseFloat(item.total_access_weight).toFixed(2) : '-' }}</th>
                    </tr>
                    <tr class="bg-gray-100 dark:bg-dark-1">
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r"></th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">GST</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Duty Total</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2">Total Amount With Duty</th>
                    </tr>
                    <tr>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">%</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst }}</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="2">{{ item.duty_amount }}</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="2" rowspan="2">{{ item.total_value }}</th>
                    </tr>
                    <tr>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Amount</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.gst_value }}</th>
                        <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess_value }}</th>
                    </tr>
                </table>
            </div>
        </div>

    </div>
</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constant/config'
import router from '@/router'
import {
    useRoute
} from 'vue-router'
import axios from 'axios'
import {
    defineComponent,
    inject,
    onMounted,
    reactive,
    ref,
    watch
} from 'vue'
import {
    stringify
} from 'json5'
import Toastify from 'toastify-js'
import {
    helper as $h
} from '@/utils/helper'

export default defineComponent({
    setup() {
        const pagedata = reactive({
            products: [],
            material_details: [],
            companies: [],
            stations: [],
            uqcs: [],
            errors: [],
            error: '',
            submit: false
        })
        const swal = inject('$swal')
        const route = useRoute()
        const formData = reactive({
            id: '',
            station_id: '',
            company_id: '',
            purchase_pcs: 1,
            insurance_no: '',
            insurance: '',
            freigth_type: '2',
            freigth: '',
            gst_invoice_no: '',
            gst_invoice_date: '',
            po_no: '',
            po_date: '',
            eway_bill_no: '',
            eway_bill_date: '',
            warehouse_receipt_time: '',
            inward_date: '',
            duty_paid: '',
            duty_amount: '',
            tax_value: '',
            total_with_tax_value: '',
            inward_value: '',
            free_sample: false,
            accessable_value: '',
            purchase_details: [{
                id: 1,
                update_id: '',
                product_id: '',
                product_name: '',
                material_id: '',
                material_name: '',
                uqc_id: '',
                uqc_id_2: '',
                different_uqc: false,
                uqc_name: '',
                uqc_name_2: '',
                product_type: '1',
                quantity: '',
                quantity_2: '',
                value_per_unit: '',
                tax_id: '',
                gst: '',
                // comp_cess: '',
                gst_value: '',
                note: '',
                // comp_cess_value: '',
                duty_amount: '',
                accessable_value: '',
                total_value: '',
                duty_paid: '',
                freigth: '',
                total_access_weight: '',
                insurance: '',
                free_sample: false,

            }],
        })
        onMounted(() => {
            getProductDetails()
            getStationPartiesDetails()
            getPurchasePartiesDetails()
            getUQCDetails()
            if (route.params.id) {
                formData.id = route.params.id
                getUpdateProductDetails()
            }
        })

        function getProductDetails() {
            axios({
                url: 'api/master/dp_product',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                pagedata.products = res.data.items
            })
        }

        function getUQCDetails() {
            axios({
                url: 'api/master/dp_uqc',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                pagedata.uqcs = res.data.items
            })
        }

        function getPurchasePartiesDetails() {
            axios({
                url: 'api/master/dp_purchase_party',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                pagedata.companies = res.data.items
            })
        }

        function getStationPartiesDetails() {
            axios({
                url: 'api/master/dp_station',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                pagedata.stations = res.data.items
            })
        }

        function calculateAccessableValue(item, i) {
            if (item.quantity && item.value_per_unit) {
                item.accessable_value = parseFloat(item.quantity * item.value_per_unit).toFixed(2)
            }

            if (item.product_id && item.material_id && item.quantity > 0) {
                // Get Product
                if (item.different_uqc == 1) {

                    for (let ind = 0; i < pagedata.material_details[i].length; ind++) {
                        if (pagedata.material_details[i][ind]['id'] == item.material_id) {
                            let new_qty_cnt = pagedata.material_details[i][ind]['new_uqc_value']
                            // console.warn(new_qty_cnt)
                            if (new_qty_cnt) {
                                item.quantity_2 = $h.myFloat(item.quantity / new_qty_cnt)
                            } else {
                                item.quantity_2 = ''
                            }
                            break
                        }
                    }
                }
            }

            calculateTotalAccessable()
            calculateTotal(item, i)
        }

        function calculateTotalAccessable() {
            let inward_value = 0;
            formData.purchase_details.forEach(element => {
                if (element.accessable_value != '' && element.accessable_value != 0) {
                    inward_value = $h.myFloat(parseFloat(element.accessable_value) + parseFloat(inward_value))
                }
            });

            formData.inward_value = inward_value
            formData.purchase_details.forEach(element => {
                if (element.accessable_value != '' && element.accessable_value != 0) {
                    if (formData.freigth) {
                        let freigth = (formData.freigth * element.accessable_value) / formData.inward_value
                        element.freigth = freigth
                    }
                    if (formData.insurance) {
                        let insurance = (formData.insurance * element.accessable_value) / formData.inward_value
                        element.insurance = insurance
                    }
                    element.total_access_weight = $h.myFloat(parseFloat(element.accessable_value) + (element.insurance ? parseFloat(element.insurance) : 0) + (element.freigth ? parseFloat(element.freigth) : 0))

                    calculateTotal(element)
                }
            });
            formData.accessable_value = (parseFloat(formData.inward_value) + (formData.insurance ? parseFloat(formData.insurance) : 0) + ((formData.freigth && formData.freigth_type != 1) ? parseFloat(formData.freigth) : 0))
        }

        function SaveManager() {

           // Validate Fileds
            let purchase_detail_length = formData.purchase_details.length

            console.warn(formData.purchase_details)
            for (let i = 0; i < purchase_detail_length; i++) {

                if (formData.purchase_details[i].different_uqc == 1 && formData.purchase_details[i].quantity_2 == '') {

                    swal({
                        title: "Warning!",
                        icon: "warning",
                        text: "Material:- '" + formData.purchase_details[i].product_name + "' and Specification:- '" + formData.purchase_details[i].material_name + "' Stock UQC Quantity is not added in Material Specification 'master'!",
                        type: "warning",
                        confirmButtonColor: "#064e3b",
                    })
                    return
                }
            }
            let is_empty = false
            let is_tax_found = false

            if (formData.gst_invoice_no == "" || formData.gst_invoice_no == null) {
                $('#gst_invoice_no').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#gst_invoice_no').removeClass('border-theme-6')
            }
            if (formData.gst_invoice_date == "" || formData.gst_invoice_date == null) {
                $('#gst_invoice_date').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#gst_invoice_date').removeClass('border-theme-6')
            }
            if (formData.po_no == "" || formData.po_no == null) {
                $('#po_no').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#po_no').removeClass('border-theme-6')
            }
            if (formData.po_date == "" || formData.po_date == null) {
                $('#po_date').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#po_date').removeClass('border-theme-6')
            }
            if (formData.station_id == "" || formData.station_id == null) {
                $('#station_id').addClass('border border-theme-6')
                is_empty = true

            } else {
                $('#station_id').removeClass('border border-theme-6')
            }
            if (formData.company_id == "" || formData.company_id == null) {
                $('#company_id').addClass('border border-theme-6')
                is_empty = true

            } else {
                $('#company_id').removeClass('border border-theme-6')
            }
            if(formData.total_with_tax_value > 50000) {

              if (formData.eway_bill_no == "" || formData.eway_bill_no == null) {
                  $('#eway_bill_no').addClass('border-theme-6')
                  is_empty = true

              } else {
                  $('#eway_bill_no').removeClass('border-theme-6')
              }
              if (formData.eway_bill_date == "" || formData.eway_bill_date == null) {
                  $('#eway_bill_date').addClass('border-theme-6')
                  is_empty = true

              } else {
                  $('#eway_bill_date').removeClass('border-theme-6')
              }
            }
            if (formData.inward_date == "" || formData.inward_date == null) {
                $('#inward_date').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#inward_date').removeClass('border-theme-6')
            }
            if (formData.freigth_type == "" || formData.freigth_type == null) {
                $('#freigth_type').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#freigth_type').removeClass('border-theme-6')
            }
            if((formData.freigth == "" || formData.freigth == null) && (formData.freigth_type == 2 || formData.freigth_type == 3 || formData.freigth_type == 4)) {
              $('#freigth').addClass('border-theme-6')
              is_empty = true

            } else {
              $('#freigth').removeClass('border-theme-6')
            }
            if (formData.warehouse_receipt_time == "" || formData.warehouse_receipt_time == null) {
                $('#warehouse_receipt_time').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#warehouse_receipt_time').removeClass('border-theme-6')
            }


            for (let i = 0; i < purchase_detail_length; i++) {

                if (formData.purchase_details[i].product_id == '' || formData.purchase_details[i].product_id == null) {
                    $('#product_id_' + i).addClass('border-theme-6');
                    is_empty = true;
                } else {
                    $('#product_id_' + i).removeClass('border-theme-6');
                }
                if (formData.purchase_details[i].material_id == '' || formData.purchase_details[i].material_id == null) {
                    $('#material_id_' + i).addClass('border-theme-6');
                    is_empty = true;
                } else {
                    $('#material_id_' + i).removeClass('border-theme-6');
                }
                if (formData.purchase_details[i].product_type == '' || formData.purchase_details[i].product_type == null) {
                    $('#product_type_' + i).addClass('border-theme-6');
                    is_empty = true;
                } else {
                    $('#product_type_' + i).removeClass('border-theme-6');
                }
                if (formData.purchase_details[i].quantity == '' || formData.purchase_details[i].quantity == null) {
                    $('#quantity_' + i).addClass('border-theme-6');
                    is_empty = true;
                } else {
                    $('#quantity_' + i).removeClass('border-theme-6');
                }
                if (formData.purchase_details[i].value_per_unit == '' || formData.purchase_details[i].value_per_unit == null) {
                    $('#value_per_unit_' + i).addClass('border-theme-6');
                    is_empty = true;
                } else {
                    $('#value_per_unit_' + i).removeClass('border-theme-6');
                }
                if (formData.purchase_details[i].accessable_value == '' || formData.purchase_details[i].accessable_value == null) {
                    $('#accessable_value_' + i).addClass('border-theme-6');
                    is_empty = true;
                } else {
                    $('#accessable_value_' + i).removeClass('border-theme-6');
                }
                if(formData.purchase_details[i].tax_id == '' || formData.purchase_details[i].tax_id == null) {
                  // $('#accessable_value_' + i).addClass('border-theme-6');
                  is_tax_found = true;
                }
            }

            if (is_empty == true) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fileds Are Empty!",
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                })
                return
            }
            if(is_tax_found == true) {
        swal({
            title: "Warning!",
            icon: "warning",
            text: "Tax Details not Found!",
            type: "warning",
            confirmButtonColor: "#064e3b",
          })
        return
      }

            pagedata.submit = true

            if (route.params.id) {
                axios({
                    url: 'api/purchase/update_dta_purchase',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: formData,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    if (res.data.error == 'YES') {
                        swal("Warning!", res.data.message, "warning");
                        pagedata.submit = false
                    } else {
                        swal({
                            title: "Success!",
                            icon: "success",
                            text: res.data.message,
                            type: "success",
                            confirmButtonColor: "#064e3b",
                        })
                        router.push({
                            name: 'dta-purchase-view'
                        })
                        pagedata.submit = false
                    }
                }).catch((err) => {
                    pagedata.errors = err.response.data.errors
                    setTimeout(() => {
                        pagedata.errors = []
                    }, 5000);
                    pagedata.submit = false
                })
            } else {
                axios({
                    url: 'api/purchase/add_new_dta_purchase',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: formData,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    if (res.data.error == 'YES') {
                        swal("Warning!", res.data.message, "warning");
                        pagedata.submit = false
                    } else {
                        swal({
                            title: "Success!",
                            icon: "success",
                            text: res.data.message,
                            type: "success",
                            confirmButtonColor: "#064e3b",
                        })
                        router.push({
                            name: 'dta-purchase-view'
                        })
                        pagedata.submit = false
                    }
                }).catch((err) => {
                    pagedata.errors = err.response.data.errors
                    setTimeout(() => {
                        pagedata.errors = []
                    }, 5000);
                    pagedata.submit = false
                })
            }
        }

        function onChangeProductId(item, i) {
            let length = pagedata.products.length

            for (let i = 0; i < length; i++) {
                if (pagedata.products[i]['id'] == item.product_id) {
                    item.uqc_id = String(pagedata.products[i]['uqc_id'])
                    item.uqc_id_2 = (pagedata.products[i]['uqc_id_2'] == null ? String(pagedata.products[i]['uqc_id']) : String(pagedata.products[i]['uqc_id_2']))
                    item.product_name = pagedata.products[i]['product_name']
                    item.different_uqc = pagedata.products[i]['different_uqc'] == 1 ? true : false
                    item.product_type = String(pagedata.products[i]['product_type'])
                }
            }
            for (let i = 0; i < pagedata.uqcs.length; i++) {
                if (pagedata.uqcs[i]['id'] == item.uqc_id) {
                    item.uqc_name = pagedata.uqcs[i]['uqc_name']
                }
                if (pagedata.uqcs[i]['id'] == item.uqc_id_2) {
                    item.uqc_name_2 = pagedata.uqcs[i]['uqc_name']
                }
            }
            getMaterialDetails(item, i, 'change')
        }

        function getProductTaxDetails(item, ind) {

            if (item.product_id && item.material_id) {

                for (let i = 0; i < pagedata.material_details[ind].length; i++) {
                    if (pagedata.material_details[ind][i]['id'] == item.material_id) {
                        item.material_name = pagedata.material_details[ind][i]['description']
                    }
                }
                const params = {
                    product_id: window.btoa(item.product_id),
                    material_id: window.btoa(item.material_id),
                    purchase_type: window.btoa(2),
                    product_type: window.btoa(item.product_type),
                    tax_id: item.tax_id ? window.btoa(item.tax_id) : '',
                }
                axios({
                    url: 'api/purchase/get_product_tax_details',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    if (res.data.items) {
                        item.gst = res.data.items.sgst ? res.data.items.sgst : ''
                        // item.comp_cess = res.data.items.comp_cess ? res.data.items.comp_cess : ''
                        item.tax_id = res.data.items.id ? res.data.items.id : ''
                    }

                    calculateTotal(item, ind)
                })
            } else {
                item.gst = ''
                // item.comp_cess = ''
                item.tax_id = ''
                calculateTotal(item, ind)
            }

            if (item.product_id && item.material_id && item.quantity > 0) {
                // Get Product
                if (item.different_uqc == 1) {

                    for (let i = 0; i < pagedata.material_details[ind].length; i++) {
                        if (pagedata.material_details[ind][i]['id'] == item.material_id) {
                            let new_qty_cnt = pagedata.material_details[ind][i]['new_uqc_value']
                            if (new_qty_cnt) {
                                item.quantity_2 = $h.myFloat(item.quantity / new_qty_cnt)
                            } else {
                                item.quantity_2 = ''
                            }
                        }
                    }
                }
            }

        }

        function calculateTotal(item) {

            if (item.accessable_value > 0) {
                const gst = parseFloat((item.gst ? item.gst : 0) * parseFloat(item.accessable_value)) / 100;

                console.warn(gst)
                // console.warn(comp_cess)
                item.gst_value = $h.myFloat(gst)
                item.duty_amount = $h.myFloat(gst)
                item.total_value = $h.myFloat(parseFloat(item.total_access_weight) + parseFloat(gst))

            }

            let gst_amount = 0
            formData.purchase_details.forEach(element => {
                gst_amount += element.gst_value
            });

            formData.tax_value = $h.myFloat(gst_amount)

            formData.total_with_tax_value = $h.myFloat((formData.tax_value ? formData.tax_value : 0) + (formData.inward_value ? formData.inward_value : 0))
        }

        function getUpdateProductDetails() {
            const params = {
                id: route.params.id
            }

            axios({
                url: 'api/purchase/show_dta_details',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {

                // formData.id = res.data.item.id
                formData.station_id = String(res.data.item.station_id)
                formData.company_id = String(res.data.item.company_id)
                formData.purchase_pcs = res.data.item.purchase_pcs
                formData.insurance_no = res.data.item.insurance_no
                formData.insurance = res.data.item.insurance
                formData.freigth_type = String(res.data.item.freigth_type)
                formData.freigth = res.data.item.freigth
                formData.gst_invoice_no = res.data.item.gst_invoice_no
                formData.gst_invoice_date = res.data.item.gst_invoice_date
                formData.po_no = res.data.item.po_no
                formData.po_date = res.data.item.po_date
                formData.eway_bill_no = res.data.item.eway_bill_no
                formData.eway_bill_date = res.data.item.eway_bill_date
                formData.warehouse_receipt_time = res.data.item.warehouse_receipt_time
                formData.inward_date = res.data.item.inward_date
                formData.duty_paid = res.data.item.duty_paid
                formData.duty_amount = res.data.item.duty_amount
                formData.inward_value = res.data.item.inward_value
                formData.free_sample = res.data.item.free_sample
                formData.accessable_value = res.data.item.accessable_value

                let purchase_details = res.data.item.purchase_details

                if (purchase_details.length > 0) {
                    formData.purchase_details = []

                    for (let i = 0; i < formData.purchase_pcs; i++) {
                        formData.purchase_details.push({
                            id: (i + 1),
                            update_id: purchase_details[i].id,
                            product_id: String(purchase_details[i].product_id),
                            material_id: String(purchase_details[i].material_id),
                            uqc_id: String(purchase_details[i].uqc_id),
                            uqc_id_2: String(purchase_details[i].uqc_id_2),
                            product_type: String(purchase_details[i].product_type),
                            different_uqc: purchase_details[i].different_uqc == 1 ? true : false,
                            quantity: purchase_details[i].quantity,
                            quantity_2: purchase_details[i].quantity_2,
                            value_per_unit: purchase_details[i].value_per_unit,
                            tax_id: purchase_details[i].tax_id,
                            gst: purchase_details[i].gst,
                            // comp_cess : purchase_details[i].comp_cess,
                            gst_value: purchase_details[i].gst_value,
                            note: purchase_details[i].note,
                            duty_amount: purchase_details[i].duty_amount,
                            accessable_value: purchase_details[i].accessable_value,
                            total_value: purchase_details[i].total_value,
                            duty_paid: purchase_details[i].duty_paid,
                            free_sample: purchase_details[i].free_sample,
                            freigth: purchase_details[i].freigth,
                            total_access_weight: purchase_details[i].total_access_weight,
                            insurance: purchase_details[i].insurance,
                        });

                        pagedata.material_details[i] = [];

                        getMaterialDetails(formData.purchase_details[i], i, 'update')
                        getProductTaxDetails(formData.purchase_details[i], i)

                        if (i == (purchase_details.length - 1)) {
                            formData.purchase_pcs = formData.purchase_details[i].id
                        }
                    }
                }
            })

        }

        // Update Freight Change
        function remove_freigth_value() {

          if(formData.freigth_type == 1 || formData.freigth_type == 5) {
            formData.freigth = ''
            calculateTotalAccessable()
          }
        }

        function getMaterialDetails(item, ind, form_type) {

            if (item.product_id) {

                let params = {
                    product_id: window.btoa(item.product_id)
                }
                axios({
                    url: 'api/master/get_details_for_tax',
                    method: 'post',
                    data: params,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    pagedata.material_details[ind] = res.data.items

                    if (form_type == 'update') {
                        for (let i = 0; i < pagedata.products.length; i++) {
                            if (pagedata.products[i]['id'] == item.product_id) {
                                item.uqc_id = String(pagedata.products[i]['uqc_id'])
                                item.uqc_id_2 = (pagedata.products[i]['uqc_id_2'] == null ? String(pagedata.products[i]['uqc_id']) : String(pagedata.products[i]['uqc_id_2']))
                                item.product_name = pagedata.products[i]['product_name']
                                item.different_uqc = pagedata.products[i]['different_uqc'] == 1 ? true : false
                            }
                        }
                        for (let i = 0; i < pagedata.uqcs.length; i++) {
                            if (pagedata.uqcs[i]['id'] == item.uqc_id) {
                                item.uqc_name = pagedata.uqcs[i]['uqc_name']
                            }
                            if (pagedata.uqcs[i]['id'] == item.uqc_id_2) {
                                item.uqc_name_2 = pagedata.uqcs[i]['uqc_name']
                            }
                        }
                        for (let i = 0; i < pagedata.material_details[ind].length; i++) {
                            if (pagedata.material_details[ind][i]['id'] == item.material_id) {
                                item.material_name = pagedata.material_details[ind][i]['description']
                            }
                        }
                    } else {
                        formData.purchase_details[ind].material_id = ''
                    }
                })
            } else {
                pagedata.material_details[ind] = []
                formData.purchase_details[ind].material_id = ''

            }

        }

        function cancelPurchase() {
            router.push({
                name: 'dta-purchase-view'
            })
        }

        function AddProductField() {
            formData.purchase_pcs = formData.purchase_pcs + 1;
            formData.purchase_details.push({
                id: formData.purchase_pcs,
                update_id: '',
                product_id: '',
                product_name: '',
                material_id: '',
                material_name: '',
                uqc_id: '',
                uqc_id_2: '',
                uqc_name: '',
                uqc_name_2: '',
                different_uqc: false,
                product_type: '1',
                quantity_2: '',
                quantity: '',
                value_per_unit: '',
                tax_id: '',
                gst: '',
                // comp_cess: '',
                gst_value: '',
                note: '',
                // comp_cess_value: '',
                duty_amount: '',
                accessable_value: '',
                total_value: '',
                duty_paid: '',
                freigth: '',
                total_access_weight: '',
                insurance: '',
                free_sample: false,
            });
            pagedata.material_details[formData.purchase_details.length - 1] = [];
        }

        function removeField(id) {
            swal({
                title: "Are you sure?",
                text: "You are about to delete a item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = formData.purchase_details.length;
                    while (i--) {
                        if (formData.purchase_details[i]["id"] == id) {
                            formData.purchase_details.splice(i, 1);
                        }
                    }
                }
            });
        }
        return {
            formData,
            pagedata,
            SaveManager,
            getMaterialDetails,
            onChangeProductId,
            calculateTotal,
            getProductTaxDetails,
            calculateAccessableValue,
            AddProductField,
            removeField,
            remove_freigth_value,
            cancelPurchase,
            calculateTotalAccessable

        }
    }
})
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
