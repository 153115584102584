<template>
  <div>
    <div class="intro-y flex items-center mt-5">
      <h2 class="text-lg font-medium mr-auto">Import Purchase</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div id="input" class="p-5">
            <div class="preview">
              <div>
                <div class="sm:grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-2">
                  <div>
                    <label for="regular-form-1" class="form-label">Bill No <span class="text-primary-3"> *</span></label>
                    <div>

                      <input v-model="formData.bill_no"
                        autocomplete="off"
                        type="text"
                        id="bill_no"
                        class="form-control"
                        placeholder="Bill No"
                        />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.bill_no">{{ pagedata.errors.bill_no[0] }}</span>
                  </div>
                  <div>
                      <div>
                        <label for="regular-form-1" class="form-label">Bill Date <span class="text-primary-3"> *</span></label>
                        <div>
                          <input v-model="formData.bill_date"
                          type="date"
                          id="bill_date"
                          autocomplete="off"
                          class="form-control"
                        />
                        </div>
                      <span class="text-primary-3" v-if="pagedata.errors.bill_date">{{ pagedata.errors.bill_date[0] }}</span>
                    </div>
                  </div>
                  <div>
                    <label for="regular-form-1" class="form-label">Po No <span class="text-primary-3"> *</span></label>
                    <div>

                      <input v-model="formData.po_no"
                        autocomplete="off"
                        type="text"
                        id="po_no"
                        class="form-control"
                        placeholder="Po No"
                        />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.po_no">{{ pagedata.errors.po_no[0] }}</span>
                  </div>
                  <div>
                      <div>
                        <label for="regular-form-1" class="form-label">Po Date <span class="text-primary-3"> *</span></label>
                        <div>
                          <input v-model="formData.po_date"
                          type="date"
                          id="po_date"
                          autocomplete="off"
                          class="form-control"
                        />
                        </div>
                      <span class="text-primary-3" v-if="pagedata.errors.po_date">{{ pagedata.errors.po_date[0] }}</span>
                    </div>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Import Station <span class="text-primary-3"> *</span></label>
                    <div id="station_id" class="p-0 m-0">
                      <TomSelect
                      v-model="formData.station_id"
                      :options="{
                            placeholder: 'Select Station'
                          }"
                          class="w-full"
                        >
                        <option value="">Select Station</option>
                          <option v-for="dept in pagedata.stations" :key="dept.id" :value="dept.id">{{ dept.station_name }}</option>
                        </TomSelect>

                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.station_id">{{ pagedata.errors.station_id[0] }}</span>
                  </div>
                  <div class="md:col-span-2 sm:col-span-1">
                    <label for="regular-form-2" class="form-label">Company <span class="text-primary-3"> *</span></label>
                    <div id="company_id" class="p-0 m-0">
                      <TomSelect
                          v-model="formData.company_id"
                          :options="{
                            placeholder: 'Select Companey'
                          }"
                          class="w-full"
                          id="company_id"
                          >
                          <option value="">Select Companey</option>
                          <option v-for="dept in pagedata.companies" :key="dept.id" :value="dept.id">{{ dept.companey_name }}</option>
                        </TomSelect>

                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.company_id">{{ pagedata.errors.company_id[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Clearance</label>
                    <div id="clearance">
                      <TomSelect
                          v-model="formData.clearance"
                          id="clearance"
                          :options="{
                            placeholder: 'Select Product Type'
                          }"
                          class="w-full"
                        >
                        <option value="1">MoowR</option>
                        <option value="2">Duty Paid</option>
                        </TomSelect>

                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.clearance">{{ pagedata.errors.clearance[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Insurance No</label>
                    <div>

                      <input v-model="formData.insurance_no"
                      id="insurance_no"
                      type="text"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Insurance No"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.insurance_no">{{ pagedata.errors.insurance_no[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Insurance</label>
                    <div>

                      <input v-model="formData.insurance"
                      id="insurance"
                      type="number"
                      @change="calculateTotalAccessable"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Insurance"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.insurance">{{ pagedata.errors.insurance[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Freight Type</label>
                    <div>
                      <TomSelect
                          v-model="formData.freigth_type"
                          :options="{
                            placeholder: 'Select Product'
                          }"
                          id="freigth_type"
                          @change="removeFreigthValue"
                          class="w-full"
                        >
                        <option value="2">Ex-W</option>
                                            <option value="4">FOB</option>
                                            <option value="5">CIF</option>
                                            <option value="1">Door Delivery</option>
                                            <option value="3">FOR</option>
                      </TomSelect>

                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.freigth_type">{{ pagedata.errors.freigth_type[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Freight</label>
                    <div>
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-4" class="input-group-text">
                          <select class="border-none outline-none bg-transparent"
                            v-model="formData.freigth_currency"
                          >
                            <option v-for="item in pagedata.currency" :key="item.id" :value="item.id">{{ item.currency_name }}</option>
                          </select>
                        </div>
                          <input
                            type="number"
                            id="freigth"
                            class="form-control"
                            v-model="formData.freigth"
                            :disabled="formData.freigth_type == 1 || formData.freigth_type == 3 || formData.freigth_type == 5"
                            @change="calculateFreigthValue"
                            autocomplete="off"
                            placeholder="Freight"
                            aria-describedby="input-group-4"
                          />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.freigth">{{ pagedata.errors.freigth[0] }}</span>
                    </div>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Conversation Value</label>
                    <div>
                      <input v-model="formData.freigth_con_value"
                      id="freigth_con_value"
                      type="number"
                      :disabled="formData.freigth_type == 1 || formData.freigth_type == 3 || formData.freigth_type == 5"
                      autocomplete="off"
                      @change="calculateFreigthValue"
                      class="form-control"
                      placeholder="Conversation Value"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.freigth_con_value">{{ pagedata.errors.freigth_con_value[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Freight Amount</label>
                    <div>
                      <input v-model="formData.freigth_value"
                      id="regular-form-1"
                      type="number"
                      disabled
                      class="form-control"
                      autocomplete="off"
                      placeholder="Freight Amount"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.freigth_value">{{ pagedata.errors.freigth_value[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-1" class="form-label">Invoice No <span class="text-primary-3"> *</span></label>
                    <div>

                      <input v-model="formData.invoice_no"
                      id="invoice_no" autocomplete="off"
                        type="text"
                        class="form-control"
                        placeholder="Invoice No"
                        />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.invoice_no">{{ pagedata.errors.invoice_no[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Invoice Date <span class="text-primary-3"> *</span></label>
                    <div>

                      <input v-model="formData.invoice_date"
                        id="invoice_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                      />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.invoice_date">{{ pagedata.errors.invoice_date[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Bond Detail</label>
                    <div>

                      <input v-model="formData.bond_detail"
                      id="bond_detail"
                      type="text"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Bond Details"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.bond_detail">{{ pagedata.errors.bond_detail[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Transport No</label>
                    <div>

                      <input v-model="formData.transport_no"
                      id="transport_no"
                      type="text"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Transport No"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.transport_no">{{ pagedata.errors.transport_no[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">One Time Lock</label>
                    <div>

                      <input v-model="formData.one_time_lock"
                      id="one_time_lock"
                      type="text"
                      autocomplete="off"
                      class="form-control"
                      placeholder="One Time Lock"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.one_time_lock">{{ pagedata.errors.one_time_lock[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Ware Receipt Date-Time <span class="text-primary-3"> *</span></label>
                    <div>

                      <input v-model="formData.warehouse_receipt_time"
                        id="warehouse_receipt_time"
                        type="datetime-local"
                        autocomplete="off"
                        class="form-control"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.warehouse_receipt_time">{{ pagedata.errors.warehouse_receipt_time[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Inward Date <span class="text-primary-3"> *</span></label>
                    <div>

                      <input v-model="formData.inward_date"
                        id="inward_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                      />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.inward_date">{{ pagedata.errors.inward_date[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Inward Amount</label>
                    <div>

                      <input v-model="formData.inward_value"
                        id="inward_value"
                        type="text"
                        disabled
                        autocomplete="off"
                        class="form-control"
                        placeholder="Inward Amount"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.inward_value">{{ pagedata.errors.inward_value[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Accessable Amount</label>
                    <div>

                      <input v-model="formData.accessable_value"
                        id="accessable_value"
                        type="text"
                        disabled
                        class="form-control"
                        autocomplete="off"
                        placeholder="Accessable Amount"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.accessable_value">{{ pagedata.errors.accessable_value[0] }}</span>
                  </div>
                  <div>
                    <label for="regular-form-2" class="form-label">Duty Type</label>
                    <div id="duty_type">
                      <TomSelect
                          v-model="formData.duty_type"
                          id="duty_type" @change="updateDutyStatus"
                          :options="{
                            placeholder: 'Select Duty Type'
                          }"
                          class="w-full"
                        >
                        <option value="1">Duty CNT</option>
                        <option value="2">Duty Free</option>
                        </TomSelect>

                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.duty_type">{{ pagedata.errors.duty_type[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>

      <div class="intro-y col-span-12 pb-3">
        <div class="intro-y box p-3">
          <h2 class="font-medium text-base mr-auto">Purchase Products</h2>
          <table class="w-full border mt-3">
            <thead>
              <tr>
                <th>Product Name <span class="text-primary-3"> *</span></th>
                <th>Material Specification <span class="text-primary-3"> *</span></th>
                <th>Product Type <span class="text-primary-3"> *</span></th>
                <th>Note</th>
                <th>Quantity <span class="text-primary-3"> *</span></th>
                <th>UQC</th>
                <!-- <th>Stock UQC</th> -->
                <th>Per Unit Rate <span class="text-primary-3"> *</span></th>
                <th>Conversation Value <span class="text-primary-3"> *</span></th>
                <th>Total Amount <span class="text-primary-3"> *</span></th>
              </tr>
            </thead>
            <tbody class="border" v-for="(plan,index) in formData.purchase_details" :key="index">
              <tr>
                <td>
                  <select
                      class="form-select focus:outline-none"
                      v-model="plan.product_id"
                      @change="onChangeProductId(plan, index)"
                      :id="'product_id_' + index"
                      aria-label="Default select example"
                    >
                    <option :value="item.id" v-for="item in pagedata.products" :key="item.id">{{ item.product_name }}</option>
                  </select>
                </td>
                <td>
                  <select
                      class="form-select"
                      v-model="plan.material_id"
                      @change="getProductTaxDetails(plan, index)"
                      :id="'material_id_' + index"
                      aria-label="Default select example"
                    >
                    <option :value="item.id" v-for="item in pagedata.material_details[index]" :key="item.id">{{ item.description }}</option>
                  </select>
                </td>
                <td>
                    <div>
                      <TomSelect
                          v-model="plan.product_type"
                          @change="getProductTaxDetails(plan, index)"
                          :options="{
                            placeholder: 'Select Product Type'
                          }"
                          class="w-32"
                          :id="'product_type_' + index"
                        >
                        <!-- <option value="3">All</option> -->
                        <option value="1" v-if="plan.product_type == 1">Poly</option>
                        <option value="2" v-if="plan.product_type == 2">MBB</option>
                        <option value="4" v-if="plan.product_type == 4">TopCon</option>
                        <option value="3" v-if="plan.product_type == 3">Both</option>
                        </TomSelect>

                    </div>
                </td>
                <td>
                  <input v-model="plan.note" type="text"
                  autocomplete="off"
                  class="form-control w-60" placeholder="Note">
                </td>
                <td>
                  <input v-model="plan.quantity" type="number"
                  :id="'quantity_' + index"
                  autocomplete="off"
                  @change="calculateAccessableValue(plan, index)"
                  class="form-control w-32" placeholder="Quantity">
                </td>
                <td>
                  <select
                      class="form-select"
                      v-model="plan.uqc_id"
                      disabled
                      aria-label="Default select example"
                    >
                    <option :value="item.id" v-for="item in pagedata.uqcs" :key="item.id">{{ item.uqc_name }}</option>
                  </select>
                </td>
                <td>
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-4" class="input-group-text">
                      <select class="border-none outline-none bg-transparent"
                        v-model="plan.value_currency"
                      >
                        <option v-for="item in pagedata.currency" :key="item.id" :value="item.id">{{ item.currency_name }}</option>
                      </select>
                    </div>
                      <input
                        type="number"
                        class="form-control w-20"
                        v-model="plan.value_per_unit"
                        :id="'value_per_unit_' + index"
                        @change="calculateAccessableValue(plan, index)"
                        autocomplete="off"
                        placeholder="Per Unit"
                        aria-describedby="input-group-4"
                      />
                  </div>
                </td>
                <td>
                  <input v-model="plan.conversation_value" type="number"
                  @change="calculateAccessableValue(plan, index)"
                  :id="'conversation_value_' + index"
                  autocomplete="off"
                  class="form-control w-32" placeholder="Conversation">
                </td>
                <td>
                  <input v-model="plan.accessable_value" type="text" disabled
                  :id="'accessable_value_' + index"
                  autocomplete="off"
                  class="form-control w-32" placeholder="Total Amount">
                </td>
                <td>
                  <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1"  @click="removeField(plan.id)">
                    <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1"/>
                  </button>
                </td>
              </tr>
              <tr v-if="plan.different_uqc">
                <td></td>
                <td></td>
                <td class="text-center font-bold">
                  Stock UQC
                </td>
                <td>
                  <input v-model="plan.quantity_2" type="text" disabled
                  class="form-control w-32" placeholder="Stock Quantity">
                </td>
                <td>
                  <select
                      class="form-select"
                      v-model="plan.uqc_id_2"
                      disabled
                      aria-label="Default select example"
                    >
                    <option :value="item.id" v-for="item in pagedata.uqcs" :key="item.id">{{ item.uqc_name }}</option>
                  </select>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>

          </table>
          <div class="flex items-center justify-between">
            <div>
                <button type="button" @click="AddProductField" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
                  Add Product
                </button>

              </div>
              <div>

<button
  type="button"
  @click="cancelPurchase"
  class="btn btn-outline-secondary w-32 mr-1"
>
  Cancel
</button>
<button type="button" class="btn btn-primary w-32" :disabled="pagedata.submit" @click="SaveManager">
  Save Details <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
</button>
</div>
          </div>
        </div>

      </div>

      <div class="intro-y col-span-12 pb-3">
        <div class="intro-y box p-3">
          <h2 class="font-medium text-base mr-auto">Purchase Duties</h2>
          <table class="w-full border mt-3" v-for="(item, index) in formData.purchase_details" :key="index">
            <tr class="bg-gray-200 dark:bg-dark-1">
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="5">{{ index + 1 }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Product Name</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">Material Specification</th>
            </tr>
            <tr>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.product_name }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" colspan="3">{{ item.material_name }}</th>
            </tr>
            <tr class="bg-gray-100 dark:bg-dark-1">
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Quantity</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">UQC</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Product Amount</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Insurance</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Freight</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">CIF Amount</th>
            </tr>
            <tr>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.quantity }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.uqc_name }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.accessable_value ? parseFloat(item.accessable_value).toFixed(2) : '-' }}</th>
              <!-- <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ (item.conversation_value && item.value_per_unit) ? (item.value_per_unit * item.conversation_value) : '' }}</th> -->
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.insurance ? parseFloat(item.insurance).toFixed(2) : '-' }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.freigth ? parseFloat(item.freigth).toFixed(2) : '-' }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.total_access_weight ? parseFloat(item.total_access_weight).toFixed(2) : '-' }}</th>
            </tr>
            <tr class="bg-gray-100 dark:bg-dark-1">
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">BCD</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Comp Cess</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">KFB</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">IGST</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Duty Total</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Total Amount With Duty</th>
            </tr>
            <tr>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">%</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.bcd }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r"></th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.igst }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="2">{{ item.duty_amount }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r" rowspan="2">{{ item.total_value }}</th>
            </tr>
            <tr>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">Amount</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.bcd_value }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.comp_cess_value }}</th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r w-48 p-0 m-0" style="padding: 0;"><div><input type="text" class="form-control m-0 text-center font-bold" @keypress="$h.preventInvalidInput" v-model="item.kfb_value" placeholder="KFB" name="" id="" @input="calculateTotal(item)"></div></th>
              <th class="py-2 border-b lg:text-md whitespace-nowrap border-r">{{ item.igst_value }}</th>
            </tr>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constant/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { defineComponent, inject, onMounted, reactive, ref, watch } from 'vue'
import { stringify } from 'json5'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import $ from 'jquery'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      products: [],
      currency: [],
      material_details: [],
      companies: [],
      stations : [],
      uqcs : [],
      errors: [],
      error: '',
      submit: false
    })
    const swal = inject('$swal')
    const route = useRoute()
    const formData = reactive({
      id: '',
      station_id: '',
      company_id: '',
      clearance: '1',
      duty_type: '1',
      purchase_pcs: 1,
      insurance_no: '',
      insurance: '',
      freigth_type: '2',
      freigth_currency: '',
      freigth: '',
      freigth_con_value: '',
      freigth_value: '',
      bill_no : '',
      bill_date : '',
      po_no : '',
      po_date : '',
      invoice_no : '',
      invoice_date : '',
      bond_detail : '',
      transport_no : '',
      one_time_lock : '',
      warehouse_receipt_time : '',
      inward_date : '',
      duty_paid: '',
      duty_amount: '',
      inward_value: '',
      free_sample : false,
      accessable_value: '',
      purchase_details: [{
        id: 1,
        update_id: '',
        product_id: '',
        product_name: '',
        material_id: '',
        material_name: '',
        uqc_id: '',
        uqc_id_2: '',
        different_uqc: false,
        uqc_name: '',
        uqc_name_2: '',
        product_type: '1',
        quantity: '',
        quantity_2: '',
        value_per_unit: '',
        tax_id: '',
        bcd: '',
        igst: '',
        kfb: '',
        comp_cess: '',
        note: '',
        freigth: '',
        total_access_weight: '',
        insurance: '',
        bcd_value: '',
        igst_value: '',
        kfb_value: '',
        comp_cess_value: '',
        duty_amount: '',
        accessable_value: '',
        total_value: '',
        conversation_value: '',
        value_currency: '',
        duty_paid: '',
        free_sample : false,

      }],
    })
    onMounted(() => {
      getProductDetails()
      getStationPartiesDetails()
      getPurchasePartiesDetails()
      getUQCDetails()
      getCurrencyDetails()
      if(route.params.id) {
        formData.id = route.params.id
        getUpdateProductDetails()
      }
    })
    function getProductDetails() {
      axios({
        url: 'api/master/dp_product',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.products = res.data.items
      })
    }
    function getCurrencyDetails() {
      axios({
        url: 'api/master/dp_currency',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.currency = res.data.items
        formData.freigth_currency = pagedata.currency[0].id

        formData.purchase_details.forEach(element => {
          element.value_currency = pagedata.currency[0].id
        });
      })
    }
    function getUQCDetails() {
      axios({
        url: 'api/master/dp_uqc',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.uqcs = res.data.items
      })
    }
    function getPurchasePartiesDetails() {
      axios({
        url: 'api/master/dp_purchase_party',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.companies = res.data.items
      })
    }
    function getStationPartiesDetails() {
      axios({
        url: 'api/master/dp_station',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.stations = res.data.items
      })
    }
    function calculateAccessableValue(item, i) {
      if(item.quantity && item.value_per_unit && item.conversation_value) {
        item.accessable_value = parseFloat(item.quantity * item.value_per_unit * item.conversation_value).toFixed(2)
      }
      // total_access_weight

      if(item.product_id && item.material_id && item.quantity > 0) {
        // Get Product
        if(item.different_uqc == 1) {

          for(let ind = 0;i < pagedata.material_details[i].length; ind++) {
            if(pagedata.material_details[i][ind]['id'] == item.material_id) {
              let new_qty_cnt = pagedata.material_details[i][ind]['new_uqc_value']
              // console.warn(new_qty_cnt)
              if(new_qty_cnt) {
                item.quantity_2 = $h.myFloat(item.quantity / new_qty_cnt)
              } else {
                item.quantity_2 = ''
              }
              break
            }
          }
        }
      }

      calculateTotalAccessable()
      // calculateTotal(item, i)
    }
    function calculateTotalAccessable() {
      let inward_value = 0;
      formData.purchase_details.forEach(element => {
        if(element.accessable_value != '' && element.accessable_value != 0) {
          inward_value = $h.myFloat(parseFloat(element.accessable_value) + parseFloat(inward_value))

        }
      });
      formData.inward_value = inward_value
      formData.purchase_details.forEach(element => {
        if(element.accessable_value != '' && element.accessable_value != 0) {
          if(formData.freigth_value) {
            let freigth = (formData.freigth_value * element.accessable_value) / formData.inward_value
            element.freigth = freigth
          }
          if(formData.insurance) {
            let insurance = (formData.insurance * element.accessable_value) / formData.inward_value
            element.insurance = insurance
          }
          element.total_access_weight = $h.myFloat(parseFloat(element.accessable_value) + (element.insurance ? parseFloat(element.insurance) : 0) + (element.freigth ? parseFloat(element.freigth) : 0))

          calculateTotal(element)
        }
      });

      formData.accessable_value = (parseFloat(formData.inward_value) + (formData.insurance ? parseFloat(formData.insurance) : 0) + ((formData.freigth_value && formData.freigth_type != 1) ? parseFloat(formData.freigth_value) : 0))
    }
    function SaveManager() {
      // Validate Fileds
      let purchase_detail_length = formData.purchase_details.length

      for (let i = 0; i < purchase_detail_length; i++) {

        if(formData.purchase_details[i].different_uqc == 1 && formData.purchase_details[i].quantity_2 == '') {

          swal({
              title: "Warning!",
              icon: "warning",
              text: "Material:- '" + formData.purchase_details[i].product_name + "' and Specification:- '" + formData.purchase_details[i].material_name +"' Stock UQC Quantity is not added in Material Specification 'master'!",
              type: "warning",
              confirmButtonColor: "#064e3b",
          })
          return
        }
      }
      let is_empty = false
      let is_tax_found = true

      if(formData.bill_no == "" || formData.bill_no == null) {
        $('#bill_no').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#bill_no').removeClass('border-theme-6')
      }
      if(formData.bill_date == "" || formData.bill_date == null) {
        $('#bill_date').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#bill_date').removeClass('border-theme-6')
      }
      if(formData.po_no == "" || formData.po_no == null) {
        $('#po_no').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#po_no').removeClass('border-theme-6')
      }
      if(formData.po_date == "" || formData.po_date == null) {
        $('#po_date').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#po_date').removeClass('border-theme-6')
      }
      if(formData.station_id == "" || formData.station_id == null) {
        $('#station_id').addClass('border border-theme-6')
        is_empty = true

      } else {
        $('#station_id').removeClass('border border-theme-6')
      }
      if(formData.company_id == "" || formData.company_id == null) {
        $('#company_id').addClass('border border-theme-6')
        is_empty = true

      } else {
        $('#company_id').removeClass('border border-theme-6')
      }
      if(formData.invoice_no == "" || formData.invoice_no == null) {
        $('#invoice_no').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#invoice_no').removeClass('border-theme-6')
      }
      if(formData.invoice_date == "" || formData.invoice_date == null) {
        $('#invoice_date').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#invoice_date').removeClass('border-theme-6')
      }
      if(formData.freigth_type == "" || formData.freigth_type == null) {
        $('#freigth_type').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#freigth_type').removeClass('border-theme-6')
      }
      if((formData.insurance == "" || formData.insurance == null) && (formData.freigth_type == 2 || formData.freigth_type == 4)) {
        $('#insurance').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#freigth').removeClass('border-theme-6')
      }
      if((formData.freigth == "" || formData.freigth == null) && (formData.freigth_type == 2 || formData.freigth_type == 4)) {
        $('#freigth').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#freigth').removeClass('border-theme-6')
      }
      if((formData.freigth_con_value == "" || formData.freigth_con_value == null) && (formData.freigth_type == 2 || formData.freigth_type == 4)) {
        $('#freigth_con_value').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#freigth_con_value').removeClass('border-theme-6')
      }
      if(formData.inward_date == "" || formData.inward_date == null) {
        $('#inward_date').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#inward_date').removeClass('border-theme-6')
      }
      if(formData.warehouse_receipt_time == "" || formData.warehouse_receipt_time == null) {
        $('#warehouse_receipt_time').addClass('border-theme-6')
        is_empty = true

      } else {
        $('#warehouse_receipt_time').removeClass('border-theme-6')
      }
      for(let i = 0;i < purchase_detail_length;i++) {


        if(formData.purchase_details[i].product_id == '' || formData.purchase_details[i].product_id == null) {
          $('#product_id_' + i).addClass('border-theme-6');
          is_empty = true;
        } else {
          $('#product_id_' + i).removeClass('border-theme-6');
        }
        if(formData.purchase_details[i].material_id == '' || formData.purchase_details[i].material_id == null) {
          $('#material_id_' + i).addClass('border-theme-6');
          is_empty = true;
        } else {
          $('#material_id_' + i).removeClass('border-theme-6');
        }
        if(formData.purchase_details[i].product_type == '' || formData.purchase_details[i].product_type == null) {
          $('#product_type_' + i).addClass('border-theme-6');
          is_empty = true;
        } else {
          $('#product_type_' + i).removeClass('border-theme-6');
        }
        if(formData.purchase_details[i].quantity == '' || formData.purchase_details[i].quantity == null) {
          $('#quantity_' + i).addClass('border-theme-6');
          is_empty = true;
        } else {
          $('#quantity_' + i).removeClass('border-theme-6');
        }
        if(formData.purchase_details[i].value_per_unit == '' || formData.purchase_details[i].value_per_unit == null) {
          $('#value_per_unit_' + i).addClass('border-theme-6');
          is_empty = true;
        } else {
          $('#value_per_unit_' + i).removeClass('border-theme-6');
        }
        if(formData.purchase_details[i].conversation_value == '' || formData.purchase_details[i].conversation_value == null) {
          $('#conversation_value_' + i).addClass('border-theme-6');
          is_empty = true;
        } else {
          $('#conversation_value_' + i).removeClass('border-theme-6');
        }
        if(formData.purchase_details[i].accessable_value == '' || formData.purchase_details[i].accessable_value == null) {
          $('#accessable_value_' + i).addClass('border-theme-6');
          is_empty = true;
        } else {
          $('#accessable_value_' + i).removeClass('border-theme-6');
        }
        if(formData.purchase_details[i].tax_id == '' || formData.purchase_details[i].tax_id == null) {
          // $('#accessable_value_' + i).addClass('border-theme-6');
          is_tax_found = false;
        }
      }

      if(is_empty == true) {
        swal({
            title: "Warning!",
            icon: "warning",
            text: "Fileds Are Empty!",
            type: "warning",
            confirmButtonColor: "#064e3b",
          })
        return
      }
      if(is_tax_found == false) {
        swal({
            title: "Warning!",
            icon: "warning",
            text: "Tax Details not Found!",
            type: "warning",
            confirmButtonColor: "#064e3b",
          })
        return
      }

      pagedata.submit = true


      if(route.params.id) {
        axios({
          url: 'api/purchase/update_import_purchase',
          method: 'post',
          baseURL: BASE_URL,
          data: formData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          if(res.data.error == 'YES') {
            swal({
            title: "Warning!",
            icon: "warning",
            text: res.data.message,
            type: "warning",
            confirmButtonColor: "#064e3b",
          })
            pagedata.submit = false
          } else {
            swal({
                  title: "Success!",
                  icon: "success",
                  text: res.data.message,
                  type: "success",
                  confirmButtonColor: "#064e3b",
                })
            router.push({ name: 'import-purchase-view' })
            pagedata.submit = false
          }
        }).catch((err) => {
          pagedata.errors = err.response.data.errors
          setTimeout(() => {
            pagedata.errors = []
          }, 5000);
          pagedata.submit = false
        })
      } else {
          axios({
            url: 'api/purchase/add_new_import_purchase',
          method: 'post',
          baseURL: BASE_URL,
          data: formData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          if(res.data.error == 'YES') {
            swal({
            title: "Warning!",
            icon: "warning",
            text: res.data.message,
            type: "warning",
            confirmButtonColor: "#064e3b",
          })
            pagedata.submit = false
          } else {
            swal({
                  title: "Success!",
                  icon: "success",
                  text: res.data.message,
                  type: "success",
                  confirmButtonColor: "#064e3b",
                })
            router.push({ name: 'import-purchase-view' })
            pagedata.submit = false
          }
        }).catch((err) => {
          pagedata.errors = err.response.data.errors
          setTimeout(() => {
            pagedata.errors = []
          }, 5000);
          pagedata.submit = false
        })
      }
    }
    function onChangeProductId(item, i) {
      let length = pagedata.products.length

      for(let i = 0;i < length; i++) {
        if(pagedata.products[i]['id'] == item.product_id) {
          item.uqc_id = String(pagedata.products[i]['uqc_id'])
          item.uqc_id_2 = (pagedata.products[i]['uqc_id_2'] == null ? String(pagedata.products[i]['uqc_id']) : String(pagedata.products[i]['uqc_id_2']))
          item.product_name = pagedata.products[i]['product_name']
          item.different_uqc = pagedata.products[i]['different_uqc'] == 1 ? true : false
          item.product_type = String(pagedata.products[i]['product_type'])
        }
      }
      for(let i = 0;i < pagedata.uqcs.length; i++) {
        if(pagedata.uqcs[i]['id'] == item.uqc_id) {
          item.uqc_name = pagedata.uqcs[i]['uqc_name']
        }
        if(pagedata.uqcs[i]['id'] == item.uqc_id_2) {
          item.uqc_name_2 = pagedata.uqcs[i]['uqc_name']
        }
      }
      getMaterialDetails(item, i, 'change')
    }
    function getProductTaxDetails(item,ind) {

      if(item.product_id && item.material_id) {

        for(let i = 0;i < pagedata.material_details[ind].length; i++) {
          if(pagedata.material_details[ind][i]['id'] == item.material_id) {
            item.material_name = pagedata.material_details[ind][i]['description']
          }
        }
        const params = {
          product_id : window.btoa(item.product_id),
          material_id : window.btoa(item.material_id),
          purchase_type : window.btoa(1),
          product_type :  window.btoa(item.product_type),
          tax_id :  item.tax_id ? window.btoa(item.tax_id) : '',
        }
        axios({
          url: 'api/purchase/get_product_tax_details',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          if(res.data.items) {
            item.bcd = res.data.items.bcd ? res.data.items.bcd : ''
            item.igst = res.data.items.sgst ? res.data.items.sgst : ''
            item.comp_cess = res.data.items.comp_cess ? res.data.items.comp_cess : ''
            item.tax_id = res.data.items.id ? res.data.items.id : ''
          }

          calculateTotal(item,ind)
        })

      } else {
        item.bcd = ''
        item.igst = ''
        item.comp_cess = ''
        item.tax_id = ''
        calculateTotal(item)
      }

      if(item.product_id && item.material_id && item.quantity > 0) {
        // Get Product
        if(item.different_uqc == 1) {

          for(let i = 0;i < pagedata.material_details[ind].length; i++) {
            if(pagedata.material_details[ind][i]['id'] == item.material_id) {
              let new_qty_cnt = pagedata.material_details[ind][i]['new_uqc_value']
              if(new_qty_cnt) {
                item.quantity_2 = $h.myFloat(item.quantity / new_qty_cnt)
              } else {
                item.quantity_2 = ''
              }
            }
          }
        }
      }

    }
    function calculateTotal(item) {

      if(item.total_access_weight > 0) {

        if(formData.duty_type == 1) {

          const bcd = parseFloat((item.bcd ? item.bcd : 0) * parseFloat(item.total_access_weight)) / 100;
          // const kfb = parseFloat((item.kfb ? item.kfb : 0) * parseFloat(item.total_access_weight)) / 100;
          const comp_cess = parseFloat((item.comp_cess ? item.comp_cess : 0) * parseFloat(bcd)) / 100;
          const igst = parseFloat((item.igst ? item.igst : 0) * parseFloat(item.total_access_weight + bcd + comp_cess + parseFloat((item.kfb_value ? item.kfb_value : 0)))) / 100;

          // console.warn(bcd)
          // console.warn(igst)
          // console.warn(comp_cess)
          item.bcd_value = $h.myFloat(bcd)
          item.kfb_value = $h.myFloat(item.kfb_value)
          item.igst_value = $h.myFloat(igst)
          item.comp_cess_value = $h.myFloat(comp_cess)
          item.duty_amount = $h.myFloat(parseFloat(bcd) + parseFloat(igst) + parseFloat(comp_cess) + parseFloat(item.kfb_value))
          item.total_value = $h.myFloat(parseFloat(item.total_access_weight) + parseFloat(bcd) + parseFloat(igst) + parseFloat(comp_cess) + parseFloat(item.kfb_value))
        } else {
          const bcd = 0;
          const comp_cess = 0;
          const igst = parseFloat((item.igst ? item.igst : 0) * parseFloat(item.total_access_weight + bcd + comp_cess + parseFloat(item.kfb_value ? item.kfb_value : 0))) / 100;

          // console.warn(bcd)
          // console.warn(igst)
          // console.warn(comp_cess)
          item.bcd_value = $h.myFloat(bcd)
          item.kfb_value = $h.myFloat(item.kfb_value)
          item.igst_value = $h.myFloat(igst)
          item.comp_cess_value = $h.myFloat(comp_cess)
          item.duty_amount = $h.myFloat(parseFloat(bcd) + parseFloat(igst) + parseFloat(comp_cess) + parseFloat(item.kfb_value))
          item.total_value = $h.myFloat(parseFloat(item.total_access_weight) + parseFloat(bcd) + parseFloat(igst) + parseFloat(comp_cess) + parseFloat(item.kfb_value))

        }



      }
    }

    function updateDutyStatus() {
      formData.purchase_details.forEach(element => {
        calculateTotal(element)
      });
    }
    function getUpdateProductDetails() {
      const params = {
        id : route.params.id
      }

      axios({
          url: 'api/purchase/show_import_details',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {

          // formData.id = res.data.item.id
          formData.station_id = String(res.data.item.station_id)
          formData.company_id = String(res.data.item.company_id)
          formData.clearance = String(res.data.item.clearance)
          formData.duty_type = String(res.data.item.duty_type)
          formData.purchase_pcs = res.data.item.purchase_pcs
          formData.insurance_no = res.data.item.insurance_no
          formData.insurance = res.data.item.insurance
          formData.freigth_type = String(res.data.item.freigth_type)
          formData.freigth_currency = String(res.data.item.freigth_currency)
          formData.freigth = res.data.item.freigth
          formData.freigth_con_value = res.data.item.freigth_con_value
          formData.freigth_value = res.data.item.freigth_value
          formData.bill_no = res.data.item.bill_no
          formData.bill_date = res.data.item.bill_date
          formData.po_no = res.data.item.po_no
          formData.po_date = res.data.item.po_date
          formData.invoice_no = res.data.item.invoice_no
          formData.invoice_date = res.data.item.invoice_date
          formData.bond_detail = res.data.item.bond_detail
          formData.transport_no = res.data.item.transport_no
          formData.one_time_lock = res.data.item.one_time_lock
          formData.warehouse_receipt_time = res.data.item.warehouse_receipt_time
          formData.inward_date = res.data.item.inward_date
          formData.duty_paid = res.data.item.duty_paid
          formData.duty_amount = res.data.item.duty_amount
          formData.inward_value = res.data.item.inward_value
          formData.free_sample = res.data.item.free_sample
          formData.accessable_value = res.data.item.accessable_value

          let purchase_details = res.data.item.purchase_details

          if(purchase_details.length > 0) {

            formData.purchase_details = []

            for(let i = 0; i < formData.purchase_pcs; i++) {
              formData.purchase_details.push({
                id : (i + 1),
                update_id : purchase_details[i].id,
                product_id : String(purchase_details[i].product_id),
                material_id : String(purchase_details[i].material_id),
                uqc_id : String(purchase_details[i].uqc_id),
                uqc_id_2 : String(purchase_details[i].uqc_id_2),
                different_uqc : purchase_details[i].different_uqc == 1 ? true : false,
                product_type : String(purchase_details[i].product_type),
                quantity : purchase_details[i].quantity,
                quantity_2 : purchase_details[i].quantity_2,
                value_per_unit : purchase_details[i].value_per_unit,
                conversation_value : purchase_details[i].value_con,
                value_currency: String(purchase_details[i].value_currency),
                tax_id : purchase_details[i].tax_id,
                bcd : purchase_details[i].bcd,
                igst : purchase_details[i].igst,
                kfb : purchase_details[i].kfb,
                comp_cess : purchase_details[i].comp_cess,
                note : purchase_details[i].note,
                bcd_value : purchase_details[i].bcd_value,
                igst_value : purchase_details[i].igst_value,
                kfb_value : purchase_details[i].kfb_value,
                duty_amount : purchase_details[i].duty_amount,
                accessable_value : purchase_details[i].accessable_value,
                total_value : purchase_details[i].total_value,
                duty_paid : purchase_details[i].duty_paid,
                free_sample : purchase_details[i].free_sample,
                freigth: purchase_details[i].freigth,
                total_access_weight: purchase_details[i].total_access_weight,
                insurance: purchase_details[i].insurance,
              });

              pagedata.material_details[i] = [];

              getMaterialDetails(formData.purchase_details[i],i,'update')
              getProductTaxDetails(formData.purchase_details[i],i)

              if(i == (purchase_details.length - 1)) {
                formData.purchase_pcs = formData.purchase_details[i].id
              }
            }
          }
        })

    }
    function calculateFreigthValue() {
      if(formData.freigth_type != '1' && (formData.freigth != '' && formData.freigth != 0) && (formData.freigth_con_value != '' && formData.freigth_con_value != 0)) {
        let freight_value = $h.myFloat(parseFloat(formData.freigth_con_value) * parseFloat(formData.freigth))
        formData.freigth_value = freight_value
      }else {
        formData.freigth_value = ''
      }
      calculateTotalAccessable()
    }
    function removeFreigthValue() {

      if(formData.freigth_type == 1 || formData.freigth_type == 3 || formData.freigth_type == 5) {
        formData.freigth_value = ''
        formData.freigth = ''
        formData.freigth_con_value = ''
        calculateFreigthValue()
      }
    }
    function getMaterialDetails(item, ind, condtn) {

      if(item.product_id) {

        let params = {
          product_id : window.btoa(item.product_id)
        }
        axios({
          url: 'api/master/get_details_for_tax',
          method: 'post',
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.material_details[ind] = res.data.items

            if(condtn == 'update') {
              for(let i = 0;i < pagedata.products.length; i++) {
                if(pagedata.products[i]['id'] == item.product_id) {
                  item.uqc_id = String(pagedata.products[i]['uqc_id'])
                  item.uqc_id_2 = (pagedata.products[i]['uqc_id_2'] == null ? String(pagedata.products[i]['uqc_id']) : String(pagedata.products[i]['uqc_id_2']))
                  item.product_name = pagedata.products[i]['product_name']
                  item.different_uqc = pagedata.products[i]['different_uqc'] == 1 ? true : false
                }
              }
              for(let i = 0;i < pagedata.uqcs.length; i++) {
                if(pagedata.uqcs[i]['id'] == item.uqc_id) {
                  item.uqc_name = pagedata.uqcs[i]['uqc_name']
                }
                if(pagedata.uqcs[i]['id'] == item.uqc_id_2) {
                  item.uqc_name_2 = pagedata.uqcs[i]['uqc_name']
                }
              }
              for(let i = 0;i < pagedata.material_details[ind].length; i++) {
                if(pagedata.material_details[ind][i]['id'] == item.material_id) {
                  item.material_name = pagedata.material_details[ind][i]['description']
                }
              }
            } else {
              formData.purchase_details[ind].material_id = ''
            }
          })
        } else {
          pagedata.material_details[ind] = []
          formData.purchase_details[ind].material_id = ''

        }

    }
    function cancelPurchase() {
      router.push({ name: 'import-purchase-view' })
    }
    function AddProductField() {
      formData.purchase_pcs = formData.purchase_pcs + 1;
      formData.purchase_details.push({
        id: formData.purchase_pcs,
        update_id: '',
        product_id: '',
        material_id: '',
        uqc_id: '',
        uqc_id_2: '',
        different_uqc: false,
        product_type: '1',
        quantity: '',
        quantity_2: '',
        value_per_unit: '',
        conversation_value: '',
        value_currency: pagedata.currency[0].id,
        tax_id: '',
        bcd: '',
        igst: '',
        kfb: '',
        comp_cess: '',
        note: '',
        bcd_value: '',
        igst_value: '',
        kfb_value: '',
        comp_cess_value: '',
        duty_amount: '',
        accessable_value: '',
        freigth: '',
        total_access_weight: '',
        insurance: '',
        total_value: '',
        duty_paid: '',
        free_sample : false,
        uqc_name: '',
        uqc_name_2: '',
        product_name: '',
        material_name: '',
      });
      pagedata.material_details[formData.purchase_details.length - 1] = [];
    }
    function removeField(id) {
      swal({
          title: "Are you sure?",
          text: "You are about to delete a item!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#064e3b",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
          showLoaderOnConfirm: true,
          cancelButtonText: "Cancel",
          allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if (result.isConfirmed) {
              var i = formData.purchase_details.length;
              while (i--) {
                  if (formData.purchase_details[i]["id"] == id) {
                      formData.purchase_details.splice(i, 1);
                  }
              }
          }
      });
    }
    return {
      formData,
      pagedata,
      SaveManager,
      getMaterialDetails,
      onChangeProductId,
      calculateTotal,
      getProductTaxDetails,
      calculateAccessableValue,
      AddProductField,
      removeField,
      calculateFreigthValue,
      removeFreigthValue,
      cancelPurchase,
      calculateTotalAccessable,
      updateDutyStatus

    }
  }
})
</script>
<style scoped>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .input-group .input-group-text-custom {
            border-width: 1px;
            --tw-border-opacity: 1;
            border-color: rgba(226, 232, 240, var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --tw-text-opacity: 1;
            color: rgba(113, 128, 150, var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    </style>

